import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { isAuthenticated } from '../../../auth';
import { sendInvitation, update_staff_admin } from '../../../controllers/staff';
import Style from "./PopUp.module.css"
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Select from 'react-select';
import { listLocation } from '../../../controllers/location';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 900,
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: 24,
};

export default function PopUp({ CheckValue, itemid,refersh ,setRefresh }) {
    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = useState(false)
    const [permissions, setPermissions] = useState({
        tag: { read: 0, edit: 0, delete: 0, create: 0 },
        location: { read: 0, edit: 0, delete: 0, create: 0 },
        category: { read: 0, edit: 0, delete: 0, create: 0 },
        user: { read: 0, edit: 0, delete: 0, create: 0 },
        event: { read: 0, edit: 0, delete: 0, create: 0 },
        staff: { read: 0, edit: 0, delete: 0, create: 0 },
        ad: { read: 0, edit: 0, delete: 0, create: 0 },
    });



    const handleEdit = async () => {
        setEdit(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/staff/get-staff-by-id?staffId=${itemid}`);
        const data = await response.json();
        setEmail(data.email);
        const cities = data?.permission.allowedCities;
        setSelectedCities(cities);
        const permissions = data?.permission;
        // Convert the permissions object to match the expected format
        const formattedPermissions = {
            tag: { ...permissions.tag },
            location: { ...permissions.location },
            category: { ...permissions.category },
            user: { ...permissions.user },
            event: { ...permissions.event },
            staff: { ...permissions.staff },
            ad: { ...permissions.ad },
        };
        const formattedPermissionsNumbers = {};
        Object.keys(formattedPermissions).forEach((key) => {
            const formattedPermission = {};
            Object.keys(formattedPermissions[key]).forEach((permission) => {
                formattedPermission[permission] = formattedPermissions[key][permission] ? 1 : 0;
            });
            formattedPermissionsNumbers[key] = formattedPermission;
        });
        setPermissions(formattedPermissionsNumbers);
    };




    const handleOpen = () => {
        if (CheckValue === 2) {
            handleEdit()
        }

        setOpen(true)


    };





    const handleClose = () => setOpen(false);
    const { token, id, permission } = isAuthenticated();
    const [Locations, setLocations] = useState([])
    const [email, setEmail] = useState('');
    const [selectedCities, setSelectedCities] = useState([]);


    const init = async () => {
        try {
            const locationData = await listLocation(id, token);
            setLocations(locationData);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        init()
    }, [])


    const handleCityChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setSelectedCities(selectedValues);
    };

    const cityOptions = Locations?.map((data) => ({
        value: data?._id,
        label: data?.locationName,
    }));

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePermissionChange = (event) => {
        const { name, value, checked } = event.target;
        setPermissions((prevPermissions) => ({
            ...prevPermissions,
            [name]: {
                ...prevPermissions[name],
                [value]: checked ? 1 : 0,
            },
        }));
    };
    const [emptyFieldError, setEmptyFieldError] = useState()
    const handleSubmit = (event) => {
        event.preventDefault();

        if(email === ""){
            setEmptyFieldError("Enter Valid Email")
            return
        }
        if(selectedCities.length === 0 ){
            setEmptyFieldError("Select atleast 1 Allowed city")
            return
        }

        if (edit === true) {
            const data = {
                email: email,
                permission: {
                    tag: permissions.tag,
                    location: permissions.location,
                    category: permissions.category,
                    user: permissions.user,
                    event: permissions.event,
                    staff: permissions.staff,
                    ad: permissions.ad,
                    allowedCities: selectedCities
                }
            }
            // console.log(data);
            update_staff_admin(itemid, data, token)
                .then(data => {
                    if (data.error) {
                        console.log("error : ", data.error);
                    } else {
                        toast.success("User Permission Updated Succesfully", {
                            position: "top-center",
                            autoClose:2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        handleClose()
                    }
                })
        }
        else {
            const data = {
                email: email,
                permission: {
                    tag: permissions.tag,
                    location: permissions.location,
                    category: permissions.category,
                    user: permissions.user,
                    event: permissions.event,
                    staff: permissions.staff,
                    ad: permissions.ad,
                    allowedCities: selectedCities
                }
            }
            sendInvitation(id, data, token)
                .then(data => {
                   
                    if(data.allready){
                        setEmptyFieldError(data.allready)
                    } 
                    else {
                        toast.success(`Email Successfully Sent to ${email}`, {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        handleClose()
                    }
                })
        }  setRefresh(!refersh)
    };

    console.log(CheckValue)

    return (
        <>

            {
                CheckValue === 1 ? <><Button onClick={handleOpen} className='addBtn' variant="contained"  sx={{ height:"max-content"}}>Add Staff</Button></> : CheckValue === 2 ? <><span className='hoverIcon' onClick={handleOpen} ><IconButton><BorderColorOutlinedIcon /></IconButton></span></> : 'Hello'
            }

            {/* <Button onClick={handleOpen} variant="contained">Add Staff</Button> */}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={Style.modal}
            >
                <Box sx={style} className={Style.popUp} >
                    <div className={Style.popUpHead}><span onClick={handleClose} ><CloseOutlinedIcon sx={{ color: 'white' }} /></span><h3>Add Staff</h3></div>
                    {permission.staff.create === 1 ?
                        <>
                            <form onSubmit={handleSubmit} className={Style.popUpForm} >
                                <div className={Style.popUpForm_mail}>
                                    <p htmlFor="email">Email *</p>
                                    <TextField className={Style.popUpForm_input} id="outlined-basic-1" value={email} name='email' label="Enter Email" type="email" variant="outlined" onChange={handleEmailChange} />
                               </div>

                                <div className={Style.popUpForm_permisionsContainer}>
                                    <h3 className={Style.popUpForm_permisionsContainer_head} >Manage Permissions</h3>
                                    <p htmlFor="email">Allowed City *</p>
                                    <Select
                                        placeholder={"Select Allowed City"}
                                        name="city"
                                        value={cityOptions.filter(option => selectedCities.includes(option.value))}
                                        options={cityOptions}
                                        isMulti
                                        onChange={handleCityChange} />
                                    <div className={Style.popUpForm_permisionsContainer_body}>

                                        {/* {Object.entries(permissions).map(([key, value]) => (
                                            <div key={key} className={Style.popUpForm_permisionsContainer_bodyColumns} >
                                                <p className={Style.popUpForm_permisionsContainer_bodyColumns_head} >{key.toUpperCase()}</p>
                                                {Object.entries(value).map(([permission, checked]) => (
                                                    <div key={permission} className={Style.popUpForm_permisionsContainer_bodyColumns_box} >
                                                       
                                                        <FormControlLabel name={key} value={permission} onChange={handlePermissionChange} control={<Checkbox />} label={permission} />
                                                       
                                                    </div>
                                                ))}
                                            </div>
                                        ))} */}
                                        {Object.entries(permissions).map(([key, value]) => (
                                            <div key={key} className={Style.popUpForm_permisionsContainer_bodyColumns}>
                                                <p className={Style.popUpForm_permisionsContainer_bodyColumns_head}>{key.toUpperCase()}</p>
                                                {Object.entries(value).map(([permission, checked]) => (
                                                    <div key={permission} className={`${Style.popUpForm_permisionsContainer_bodyColumns_box} totalNumbers `}>
                                                        <FormControlLabel
                                                            name={key}
                                                            value={permission}
                                                            onChange={handlePermissionChange}
                                                            control={<Checkbox checked={checked === 1} />} // Update checked prop here
                                                            label={permission}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        ))}


                                    </div>
                                </div>
                                {emptyFieldError && <p style={{ color: "red", fontSize: "14px" }}>{emptyFieldError}</p>}
                                <Button variant="contained" className='addBtn' sx={{ width: '60px' }} type='submit' >{CheckValue === 2 ? "Save" : "Invite"}</Button>
                            </form>
                        </>
                        :
                        <>
                            <center>
                                <h3>Access Denied!!<br /> Contact Admin For More Details</h3>
                            </center>
                        </>}
                </Box>
            </Modal>
            {/* <ToastContainer /> */}
        </>
    );
}