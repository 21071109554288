const url = process.env.REACT_APP_API_URL

export const createTag = (id,data,token) => {
    return (
        fetch(`${url}/tag/create?staff=${id}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization : `Bearer ${token}`
            },
            body: JSON.stringify(data)
            // body: data
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}


export const listTag = async (id,token) => {
    try {
        const response = await fetch(`${url}/tag/list?staff=${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}



export const editTag = async(data,id,tag_id,token)=>{
    return(
        fetch(`${url}/tag/update?id=${tag_id}&staff=${id}`, {
            method: "PUT",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization : `Bearer ${token}`
            },
            body: JSON.stringify(data)
            // body: data
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}



export const deletTag = async(id,tag_id,token) =>{
    try{
        const response = await fetch(`${url}/tag/delete?id=${tag_id}&staff=${id}`,{
            method:'DELETE',
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        const data = await response.json()
        return data
    }catch(error){
        return error
    }
}

