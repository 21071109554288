import React, { useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from "../../../components/Tables/Table";
import Button from '@mui/material/Button';
import RightSlide from "../RightSlide/RightSlide"
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Time from 'react-time-format'
import { deletEvent } from '../../../controllers/newsletter';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isAuthenticated } from '../../../auth';

import Chip from '@mui/material/Chip';
import moment from 'moment';

export default function SimpleAccordion({ data }) {
    const { token, id } = isAuthenticated();
    



    



    // console.log(data);

    const TableHead = ['Title', 'Venue', 'Start Date', 'End Date', 'Expiry Date', 'Status' ,'Actions']

    const [expandedIndex, setExpandedIndex] = useState(null);





    const showTable = (index) => {
        setExpandedIndex(index === expandedIndex ? null : index)
    }


    // console.log(TableData);


    

    return (
        <>
            <div>
                {data?.map((item, index) => (
                    <Accordion key={index} expanded={index === expandedIndex} onChange={() => showTable(index)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p>{item.categoryName} ({item.events.length || 0})</p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table TableData={item.events} TableHeading={TableHead} />
                            {/* hello */}
                        </AccordionDetails>
                    </Accordion>
                ))}

            </div>
           
        </>
    );
}