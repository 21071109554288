import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

const Ad_tracking = () => {
 
    const { ad_details, news_letter_tracking_id, user_who_clicked_ad_link } = useParams();
    const init = async () => {
        const x = await fetch(`${process.env.REACT_APP_API_URL}/event/adTrack?ad_details=${ad_details}&user_who_clicked_ad_link=${user_who_clicked_ad_link}&news_letter_tracking_id=${news_letter_tracking_id}`)
        const data = await x.json()
        if (data.ad_link) {
            window.location.href = data.ad_link
        }
        else {
            alert('URL not working')
        }

    }

    useEffect(() => {
        init()
    })
    return (
        <div>

        </div>
    )
}

export default Ad_tracking
