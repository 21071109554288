const url = process.env.REACT_APP_API_URL

export const sendInvite = (user) => {
    return (
        fetch(`${url}/staff/sendInvitation`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                // Authorization : `Bearer ${token}`
            },
            body: JSON.stringify(user)
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}

export const signup = (user) => {
    return (
        fetch(`${url}/staff/register`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                // Authorization : `Bearer ${token}`
            },
            body: JSON.stringify(user)
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}


export const signin = (user) => {
    return (
        fetch(`${url}/staff/signin`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(user)
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}

export const signout = (next) => {
    if (typeof window !== 'undefined') {
        localStorage.removeItem('Weekendo')
        if (next && typeof next === 'function') {
            next();
        }

        return fetch(`${url}/staff/signout`, {
            method: "GET"
        })
            .then(response => {
                console.log('signout', response)
            })
            .catch(err => console.log(err))
    }
}


let inactivityTimeout;


const resetInactivityTimer = () => {
    clearTimeout(inactivityTimeout);
    inactivityTimeout = setTimeout(signout, 60 * 60 * 1000); // 60 minute in milliseconds
};

const resetInactivityTimerOnUserActivity = () => {
    clearTimeout(inactivityTimeout);
    resetInactivityTimer();
};

export const authenticate = (data, next) => {
    if (typeof window !== 'undefined') {
        localStorage.setItem('Weekendo', JSON.stringify(data))
        resetInactivityTimer();
        if (next && typeof next === 'function') {
            next();
        }
    }
}


export const isAuthenticated = () => {
    if (typeof window == 'undefined') {
        return false;
    }
    if (localStorage.getItem('Weekendo')) {
        resetInactivityTimer();
        return JSON.parse(localStorage.getItem('Weekendo'));
    } else {
        return false;
    }
};



// Add event listeners to reset inactivity timer on user activity
window.addEventListener('mousemove', resetInactivityTimerOnUserActivity);
window.addEventListener('keydown', resetInactivityTimerOnUserActivity);
