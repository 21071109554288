import React, { useEffect, useState } from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import Style from "../RightSlide/RightSlide.module.css"
import { createTag, deletTag, editTag, listTag } from '../../../controllers/tag'
import { isAuthenticated } from '../../../auth';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Textarea from '@mui/joy/Textarea';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';


export default function RightSlide({tags,refersh,setRefresh, CheckValue, itemId }) {

    const { token, id, permission } = isAuthenticated();

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [tag, setTag] = useState('')
    const [description, setDescription] = useState('')
    // const [tags, setTags] = useState([])
    const [tagID, setTagId] = useState()
    const [edit, setEdit] = useState(false)
    const [Check, setCheck] = useState(false)


    // Updating 

    const handleEdit = (id) => {
        setEdit(true);
        const data = tags.filter((data) => data._id === id);
        setTagId(id);
        setTag(data[0].tagName);
        setDescription(data[0].description);
    };

    // Toggle Drawer 

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        if (CheckValue === 2 && open === true) {
            handleEdit(itemId)
        }



        setState({ ...state, [anchor]: open });

    };

    const closeDrawer = (anchor, open) => (event) => {
        if (tag === "" || description === "") {
            return
        }
        setState({ ...state, [anchor]: open });
    }

    const handleChange = (e) => {
        setTag(e.target.value)
    }

    //  for adding tag
    const [emptyFieldError, setEmptyFieldError] = useState()
    const handleSubmit = (e) => {
        e.preventDefault()
        setCheck(!Check)
        if (tag === "" || description === "") {
            setEmptyFieldError("All field are required")
            return
        }
        if (edit === false) {
            const data = {
                tagName: tag,
                description: description,
                createdBy: id,
            }
            createTag(id, data, token)
                .then(data => {
                    if (data.error) {
                        console.log("error : ", data.error);
                    } else {
                        toast.success('Tag Added Successfully', {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        window.location.reload();
                    }
                })
        } else {
            const data = {
                tagName: tag,
                description: description,
                // createdBy:"",
            }
            editTag(data, id, tagID, token).then(data => {
                if (data.error) {
                    console.log("error : ", data.error);
                } else {
                    toast.success('Tag Updated Successfully', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }).catch(error => console.error(error))
        }
        setRefresh(!refersh)
    }

    // List Of Right Slide 

    const list = (anchor) => (
        <Box
            className={Style.rightSlide}
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500 }}
            role="presentation"
        >
            <div className='rightSlide_head'><span onClick={toggleDrawer(anchor, false)} ><IconButton><CloseOutlinedIcon sx={{ color: 'white' }} /></IconButton></span><p>Add Tag</p></div>
            <div className={Style.rightSlide_form}>
                <form onSubmit={handleSubmit}>
                    <div className={Style.rightSlide_formInput}>
                        <TextField className={Style.rightSlide_formInput_input} id="outlined-basic-1" label="Enter Tag Name *" variant="outlined" onChange={handleChange} value={tag} />
                    </div>
                    <div className={Style.rightSlide_formTextarea}>
                        <Textarea
                            minRows={2}
                            placeholder="Enter Tag Description *"
                            size="md"
                            variant="outlined"
                            value={description}
                            onChange={(e) => {
                                if(e.target.value.length <= 100){
                                    setDescription(e.target.value)
                                }else{
                                    return
                                }                                
                            }}
                        />
                    <span style={{fontSize:"12px"}}>(Max 100 char)</span>
                    </div>
                    {emptyFieldError && <p style={{ color: "red", fontSize: "14px" }}>{emptyFieldError}</p>}
                    <div className={Style.rightSlide_formBtn}>
                        <Button onClick={closeDrawer(anchor, false)} variant="contained" type='submit' className='addBtn' >{CheckValue === 1 ? "Add" : "Save"}</Button>
                    </div>
                </form>
            </div>
        </Box>
    );



    // for listing all the tags
    // const init = async () => {
    //     listTag(id, token).then(data => {
    //         console.log(data)
    //         setTags(data)
    //     }).catch(error => console.error(error));

    // };

    // useEffect(() => {
    //     init();
    // }, [Check]);

    // List for Right Slide 








    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>

                    {CheckValue === 1 ? <><Button onClick={toggleDrawer(anchor, true)} className='addBtn' variant="contained" sx={{ background: '#1976d2', color: 'white' }}>Add Tags</Button>
                    </> : CheckValue === 2 ? <><span className='hoverIcon' onClick={toggleDrawer(anchor, true)}   ><IconButton><BorderColorOutlinedIcon /></IconButton></span>
                    </> : ''}

                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
            {/* <ToastContainer /> */}
        </div>
    );
}