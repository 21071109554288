import { useState } from "react"
import { authenticate, isAuthenticated, signin } from "../../auth"
import { Link, Redirect } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Style from "../Login/login.module.css"
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { forgot_password_send_mail } from "../../controllers/forgotPassword";
const Login = () => {

    const [values, setValues] = useState({
        email: '',
        password: '',
        error: '',
        redirectToReferrer: false
    })

    const { email, password, error, redirectToReferrer } = values

    const { user } = isAuthenticated()
    const [ForgotPassword, setForgotPassword] = useState(false);
    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value })
    }

    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        signin({ email, password }).then(data => {
            if (data.error) {
                console.log(data.error)
                setValues({ ...values, error: data.error, redirectToReferrer: false });
                toast.error(data.error, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                // console.log(data);

                authenticate(
                    data, () => {
                        setValues({
                            ...values,
                            redirectToReferrer: true
                        })
                    });

            }
            setLoading(false)
        });
    }

    const showError = () => (
        <div style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const redirectUser = () => {
        if (redirectToReferrer) {
            return <Redirect to="/dashboard" />;
        }
        if (isAuthenticated()) {
            return <Redirect to="/dashboard" />;
        }
    }





    const textfieldStyle = {
        width: '300px',
        margin: '10px 0'
    }

    const btnStyle = {
        width: '300px',
        height: '50px',
        margin: '10px 0',
        backgroundColor: '#4287f5',

    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius:"7px"
    };

    const [forgotEmail,setForgotEmail] = useState()
    const [forgotEmailerror,setForgotEmailerror] = useState()
    const [forgotEmailsuccess,setForgotEmailSuccess] = useState()

    const sendForgotEmail = async  (e) =>{
        e.preventDefault();
        const data = {
            email:forgotEmail
          }
          await forgot_password_send_mail(data).then(data=>{
            if(data.error){
                setForgotEmailerror(data.error)
            }
            else{
                setForgotEmailSuccess(data.message)
            }
          })
    }

    const ForgotPasswordClose = () => {
        setForgotPassword(false);
    }

    return (
        <>

            <form>
                {/* <div
                    style={{
                        width: "max-content",
                        margin:"20px",
                        padding:"10px 10px",
                        borderRadius:"10px",
                        border:"1px solid grey",
                        fontSize:"30px"
                    }}
                >
                      SIGN IN<br/>
                    <input type="text" name="email" placeholder="Email" value={email} onChange={handleChange('email')} /><br />
                    <input type="password" name="password" placeholder="Password" value={password} onChange={handleChange('password')} /><br />
                    <button onClick={handleSubmit}>Login</button>
                </div> */}

                <div className={`${Style.admin_hireflax_form_container} flex flex-wrap flex-ac`}>

                    <div className={`${Style.admin_forimage}`}>
                        <img src="\Assets\LoginPage\1.jpg" alt="login" />
                    </div>
                    <div className={`${Style.admin_login_form} flex flex-column flex-ac flex-jcc`}>
                        <img src="/Assets/MailAsset/logo.png" alt="logo" width="45%" />
                        <div className={`${Style.admin_login_heading} flex`}>
                            <span>SIGN IN</span>
                        </div>
                        <div className={`${Style.form_container} flex flex-column`}>
                            <div>

                                <div className="flex flex-column">
                                    <TextField
                                        id="standard-search"
                                        label="Email"
                                        type="email"
                                        name="email" value={email} onChange={handleChange('email')}
                                        variant="standard"
                                        style={textfieldStyle}

                                        required
                                    />

                                    <TextField
                                        id="filled-password-input"
                                        label="Password"
                                        type="password"
                                        name="password" value={password} onChange={handleChange('password')}
                                        autoComplete="current-password"
                                        variant="standard"
                                        style={textfieldStyle}
                                        required
                                    />
                                    <div className="forgot-password flex flex-jfe" style={{ cursor: 'pointer' }} onClick={() => setForgotPassword(true)}><span>Forgot Password?</span></div>
                                    <Button
                                        endIcon={<ArrowForwardRoundedIcon />}
                                        loading={loading}
                                        loadingPosition="end"
                                        variant="contained"
                                        style={btnStyle}
                                        onClick={handleSubmit}
                                    >
                                        Login
                                    </Button>

                                </div>

                            </div>
                            <br />

                        </div>
                    </div>
                </div>







            </form>
            {/* {showError()} */}
            {redirectUser()}


            <Modal open={ForgotPassword} >

                <Box sx={style} className={Style.forgetPass} >
                    <div className=' flex flex-column flex-ac' >
                        <div className='flex flex-jfe' style={{ position:"relative",width: '100%' }}>
                            <ClearRoundedIcon style={{ cursor: 'pointer' }} onClick={ForgotPasswordClose} />
                        </div>

                        <div style={{padding:"0 0 30px 0"}}>
                            <span style={{ fontSize: '25px' }}>Forgot Password</span>
                        </div>
                        <div style={{ margin: ' 5px 0', width: '100%' }}>
                            <TextField 
                            id="outlined-basic" 
                            label="Enter Email" 
                            type="email" 
                            size='small' 
                            variant="outlined" 
                            value={forgotEmail} 
                            onChange={(e) => setForgotEmail(e.target.value)} 
                            sx={{ width: '100%' }} 
                            />
                        </div>
                        
                        <div style={{ margin: '5px', fontSize: '12px', width: '100%' }}>
                            <span style={{color:"green"}}>{setForgotEmailSuccess}</span>
                        </div>
                         <div style={{ margin: '5px', fontSize: '12px', width: '100%' }}>
                            <span style={{color:"red"}}>{forgotEmailerror}</span>
                        </div>
                        <br/>
                        <Button 
                        variant="contained" 
                        sx={{ width: '100%' }} 
                        onClick={sendForgotEmail}
                        >
                            SUBMIT
                            </Button>
                    </div>
                </Box>
            </Modal>


            <ToastContainer />

        </>
    )
}

export default Login 