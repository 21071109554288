const url = process.env.REACT_APP_API_URL


export const listAd= async (id,city_id,token) => {
    try {
        const response = await fetch(`${url}/ad/list?staff=${id}&city=${city_id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}



export const deletAdd = async(id,event_id,token) =>{
    try{
        const response = await fetch(`${url}/ad/delete?id=${event_id}&staff=${id}`,{
            method:'DELETE',
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        const data = await response.json()
        return data
    }catch(error){
        return error
    }
}