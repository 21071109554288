import React from 'react'
import Style from "./Table.module.css"

const Table = ({ TableData, TableHeading, Admodule, aling}) => {

    // console.log('TableData', TableData)

    return (
                <>
                <div className={Style.table}>
                    <table>
                        <thead style={{ height: '50px' }}  >
                            <tr>
                                {
                                    TableHeading.map((data, index) => (
                                        <td key={index} align={data === "User Tag" ? "center" : ""}>{data}</td>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {TableData?.length > 0 && TableData?.map((ex, index) => {
                                return <tr style={{ height: '50px' }} key={index}  >
                                    {
                                        ex.map((item, idx) => (
                                            <td key={idx} className={aling === 1 ? Style.aling : ''}  >{item}</td>
                                        ))
                                    }
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </>
    )
}

export default Table