import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

const Event_tracking = () => {


    const { event_details, news_letter_tracking_id, user_who_clicked_event_link } = useParams();
    console.log(news_letter_tracking_id);
    const init = async () => {
        const x = await fetch(`${process.env.REACT_APP_API_URL}/event/eventTrack?event_details=${event_details}&user_who_clicked_event_link=${user_who_clicked_event_link}&news_letter_tracking_id=${news_letter_tracking_id}`)
        const data = await x.json()
        if (data.event_link) {
            window.location.href = data.event_link
        }
        else {
            alert('URL not working')
        }

    }

    useEffect(() => {
        init()
    })
    return (
        <div>

        </div>
    )
}

export default Event_tracking
