import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import GmailPageOne from './GmailPageOne/GmailPageOne'
import GmailPageTwo from "./GmailPageTwo/GmailPageTwo"
import GmailPageThree from './GmailPageThree/GmailPageThree';
import { Gmail_Page } from '../../controllers/newsletter'
import GmailPageFour from './GmailPageFour/GmailPageFour';

const GmailPageIndex = () => {
  const { template, id, cityId, newsletterId, categoryId } = useParams()

  console.log(template);

  const [newsletterData, setNewsletterData] = useState()

  const init = async () => {
    await Gmail_Page(id, cityId, newsletterId,template).then(data => {
      if (data.error) {
        console.log(data.error);        // newsletter opened on webpage count also included in it
      }
      else {
        setNewsletterData(data)
      }
    })
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    document.title = "Weekendo Newsletter for this week. Have fun!"; // Set the initial title when the component mounts
    // You can also set the title based on the current state or props

    return () => {
      // Reset the title when the component unmounts
      document.title = "Weekendo Newsletter for this week. Have fun!";
    };
  }, []);
  return (
    <>
      {template === "1" && <GmailPageOne id={id} newsletterData={newsletterData} newsletterId={newsletterId} click={true} categoryId={categoryId} />}
      {template === "2" && <GmailPageTwo id={id} newsletterData={newsletterData} newsletterId={newsletterId} click={true} categoryId={categoryId} />}
      {template === "3" && <GmailPageThree id={id} newsletterData={newsletterData} newsletterId={newsletterId} click={true} categoryId={categoryId} />}
      {template === "4" && <GmailPageFour id={id} newsletterData={newsletterData} newsletterId={newsletterId} click={true} categoryId={categoryId} />}
    </>
  )
}

export default GmailPageIndex
