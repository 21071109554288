const url = process.env.REACT_APP_API_URL

export const createCategory = (id,data,token) => {
    return (
        fetch(`${url}/category/create?staff=${id}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization : `Bearer ${token}`
            },
            body: JSON.stringify(data)
            // body: data
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}


export const listCategory = async (id,filter,token) => {
    try {
        const response = await fetch(`${url}/category/list?staff=${id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(filter)
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}



export const editCategory = async(id,data,category_id,token)=>{
    return(
        fetch(`${url}/category/update?id=${category_id}&staff=${id}`, {
            method: "PUT",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization : `Bearer ${token}`
            },
            body: JSON.stringify(data)
            // body: data
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}



export const deletCategory = async(id,category_id,token) =>{
    try{
        const response = await fetch(`${url}/category/delete?staff=${id}&id=${category_id}`,{
            method:'DELETE',
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        const data = await response.json()
        return data
    }catch(error){
        return error
    }
}

export const change_category_isactive_status = async(id,category_id,token) =>{
    try{
        const response = await fetch(`${url}/category/update/status?staff=${id}&id=${category_id}`,{
            method:'GET',
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        const data = await response.json()
        return data
    }catch(error){
        return error
    }
}















export const fetch_category_weekendo_website= async () => {
    try {
        const response = await fetch(`${url}/category/fetch-category-weekendo-website`, {
            method: 'GET'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}



















export const shuffle_category = (id,data,token) => {
    return (
        fetch(`${url}/category/shuffle-category?staff=${id}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization : `Bearer ${token}`
            },
            body: JSON.stringify(data)
            // body: data
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}