import React from 'react'
import Style from "./Form.module.css"
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';



const Form = () => {

    const Categories = [ 'NightLife', 'Eat-outs', 'Movies', 'Events', 'Theatre', 'Arts', 'Adventure Sports', 'Health', 'Personal Care', 'Workshops', 'Conferences', 'Shopping', 'Socail Work', 'Collabs'  ]

    return (
        <div className={Style.form}>
            <div className={Style.formUpper}>
                <h3>Registrer Me For FREE!!!</h3>
                <div className={Style.formInputs}>
                    <div className={Style.formInputs_section}>
                        <TextField className={Style.formInput} id="standard-basic" label="First Name" variant="standard" />
                        <TextField className={Style.formInput} id="standard-basic" label="Phone Number" variant="standard" />
                        <TextField className={Style.formInput} id="standard-basic" label="Job Title" variant="standard" />
                    </div>
                    <div className={Style.formInputs_section}>
                        <TextField className={Style.formInput} id="standard-basic" label="last Name" variant="standard" />
                        <TextField className={Style.formInput} id="standard-basic" label="City" variant="standard" />
                        <TextField className={Style.formInput} id="standard-basic" label="Company Name" variant="standard" />
                    </div>
                    <div className={Style.formInputs_section}>
                        <TextField className={Style.formInput} id="standard-basic" label="Email" variant="standard" />
                        <TextField className={Style.formInput} id="standard-basic" label="Gender" variant="standard" />
                        <TextField className={Style.formInput} id="standard-basic" label="Date of Birth" variant="standard" />
                    </div>
                </div>
            </div>
            <div className={Style.fromLower}>
                <div style={{display:'flex', gap:'20px', alignItems:'center'}} >
                    <h4>Select Categories</h4>
                    <span style={{fontSize:'13px', opacity:'0.8'}} >(Select minimum five)</span>
                </div>
                <div className={Style.formCategories}>
                    { 
                        Categories.map((data,index)=>(
                            <FormControlLabel className={Style.checkBox} control={<Checkbox defaultChecked />} label={data} />
                        ))
                     }
                </div>
            </div>
            <Button variant="contained" style={{ background:'rgba(125, 206, 19, 1)', padding:'5px 25px', borderRadius:'20px'}} >I AM IN!</Button>
            <div className={Style.bottomLeft} style={{ background: `url(${process.env.PUBLIC_URL}/Assets/Form/BottomLeft.svg)`}}></div>
            <div className={Style.topRight} style={{ background: `url(${process.env.PUBLIC_URL}/Assets/Form/TopRight.svg)`}}></div>
        </div>
    )
}

export default Form