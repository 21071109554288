import React, { useEffect, useRef, useState } from 'react'
import RightSlide from "./RightSlide/RightSlide"
import Style from "../User/User.module.css"
import { createUser, deletUser, listUser } from '../../controllers/user'
import { isAuthenticated } from '../../auth';
import { listCategory } from '../../controllers/category';
import { listLocation } from '../../controllers/location';
import { listTag } from '../../controllers/tag';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Table from "../../components/Tables/Table"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Time from 'react-time-format'
import IconButton from '@mui/material/IconButton';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Result, Spin, Tag } from 'antd';

import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Select from 'react-select';
import FilterUser from './fiilter';
import { get_staff_by_id } from '../../controllers/staff';

const User = () => {


  const { token, id } = isAuthenticated();

  const [permission, setPermission] = useState()
  const fetch_user_data = () => {
    get_staff_by_id(id).then(data => {
      if (data.error) { console.log(data.error); }
      else { setPermission(data.permission) }
    })
  }

  useEffect(() => {
    fetch_user_data()
  }, [])





  const [allUsers, setAllUsers] = useState([])
  const [Categorys, setCategorys] = useState([])
  const [Locations, setLocations] = useState([])
  const [Tags, setTags] = useState([])
  const [searchCityID, setSearchCityID] = useState([])

  const [filterUser, setFilteruser] = useState(false)
  const [filterCity, setFilterCity] = useState([])
  const [filterCategory, setFilterCategory] = useState([])


  const handleDelete = (user_id) => {
    deletUser(id, user_id, token).then(data => {
      if (data.message) {
        handleClose()
        toast.success(data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } if (data.error) {
        toast.error(data.error, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      init();
    })
  }


  const CityData = { city: searchCityID }

  // console.log(CityData)

  const [refersh, setRefresh] = useState(true)


  const init = async () => {

    const filter = {
      category: filterCategory,
      city: filterCity
    }

    await listUser(id, filter, token).then(data => {
      // console.log(data)
      setAllUsers(data)
    }).catch(error => console.error(error));

    const xyz = { city: [] }
    await listCategory(id, xyz, token).then(data => {
      // console.log(data)
      setCategorys(data)
    }).catch(error => console.error(error));

    await listLocation(id, token).then(data => {
      // console.log(data)
      setLocations(data)
    }).catch(error => console.error(error));

    await listTag(id, token).then(data => {
      // console.log(data)
      setTags(data)
    }).catch(error => console.error(error));

  }

  useEffect(() => {
    init()
  }, [refersh, filterUser])

  // Table Content 

  const TableHeading = ['Weekendo ID', 'Name', 'Email', 'User Tag', 'Date of Joining', 'Actions']

  const Keys = ['user_unique_id', 'name', 'userEmail', 'tag', 'createdAt', '_id']

  const [reverse, setreverse] = useState()

  const reverseArray = () => {
    const x = allUsers.length > 0 ? allUsers?.reverse() : []
    setreverse(x)
  }

  useEffect(() => {
    reverseArray()
  }, [allUsers])



  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteid] = useState()

  const handleClickOpen = (id) => {
    setDeleteid(id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

 

  const TableData = reverse?.map(item => Keys.map(Key => {
    if (Key === '_id') {
      return <>
        {permission  && permission.user.edit === 1 && Tags && Locations && Categorys &&<RightSlide locationAllData={Locations} userAllData={allUsers} categoryAllData={Categorys} tagAllData={Tags} refersh={refersh} setRefresh={setRefresh} itemId={item?._id} CheckValue={2} />}
        {permission && permission.user.delete === 1 && <span className='hoverIcon'><IconButton><DeleteOutlineOutlinedIcon onClick={() => handleClickOpen(item._id)} /></IconButton></span>}
      </>
    } if (Key === 'name') {
      return `${item.name.firstName} ${item.name.lastName}`
    }
    if (Key === 'tag') {
      return <center>{item.tag.tagName}</center>
    }
    if (Key === 'createdAt') {
      return <Time value={item.createdAt} format="DD-MM-YYYY" />
    }
    else {
      return item[Key] || "";
    }
  }));


  const [loading,setLoading] = useState(true)

  useEffect(()=>{
      setLoading(true);
    
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 500);
    
      return () => clearTimeout(timeout);
  },[permission])




  // console.log(allUsers)

  return (
    <>
      {loading ?<div style={{width:"100%",height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <Spin size='large'/>

      </div> : 
      <>
       {
        (permission && permission.user.read === 1) ?
          <div className={Style.user}>
            <div className={Style.userHead}>
              <p className='headDetail' >
                <h3>Users</h3>
                <span className='totalNumbers' >Total Users : {allUsers?.length}</span>
              </p>


              <div style={{ display: "flex", gap: '10px' }}>
                <FilterUser
                  filterCategory={filterCategory}
                  filterCity={filterCity}
                  setFilteruser={setFilteruser}
                  filterUser={filterUser}
                  setFilterCategory={setFilterCategory}
                  setFilterCity={setFilterCity}
                  Locations={Locations}
                  Categorys={Categorys}
                />
                {permission && permission.user.create === 1 && Tags && Locations && Categorys && <RightSlide locationAllData={Locations} categoryAllData={Categorys} tagAllData={Tags} refersh={refersh} setRefresh={setRefresh} CheckValue={1} />}
              </div>
            </div>
            {allUsers?.length > 0 ?
              <>
                <div className={Style.userTable}>
                  <Table TableData={TableData} TableHeading={TableHeading} aling={1} ></Table>
                </div>
              </>
              :
              <>
                No User Available
              </>}

          </div>
          :
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
          />
      }
      </>
      }
     
      <ToastContainer />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to delete this user
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={() => handleDelete(deleteId)} >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default User;
