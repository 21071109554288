import React, { useState, useEffect } from 'react';
import Style from "./Tab.module.css"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { EditorState, convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { fetch_setting, update_profile, update_profile_password, update_setting } from '../../../controllers/setting';
import { isAuthenticated } from '../../../auth';
import { listUser } from '../../../controllers/user';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { get_staff_by_id } from '../../../controllers/staff';
import { Divider, Result, Spin } from 'antd';
import { delete_the_expire_event } from '../../../controllers/newsletter';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
function TabPanel(props) {
    const { children, value, index, ...other } = props;



    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




export default function BasicTabs() {


    const { token, id } = isAuthenticated();

    const [value, setValue] = React.useState(0);



    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [copyRightState, setCopyRightState] = useState(EditorState.createEmpty());
    const [editorNote, setEditorNote] = useState(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    const [copyRight, setCopyRight] = useState(draftToHtml(convertToRaw(copyRightState.getCurrentContent())))

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        console.log('editorstate')
    };

    const onCopyRightStateChange = (copyRightState) => {
        setCopyRightState(copyRightState)
        console.log('copyRight')
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setEditorNote(draftToHtml(convertToRaw(editorState.getCurrentContent())))

    }, [
        draftToHtml(convertToRaw(editorState.getCurrentContent())), editorState

    ])
    useEffect(() => {

        setCopyRight(draftToHtml(convertToRaw(copyRightState.getCurrentContent())))
    }, [draftToHtml(convertToRaw(copyRightState.getCurrentContent()))])

    const [eventutm, setEventutm] = useState("")
    const [adsutm, setAdsutm] = useState("")
    const [footerData, setFooterData] = useState({
        phone_number: "",
        intsagram: "",
        linkedin: "",
        email: "",
    })

    const [userData, setUsearData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: ""
    })

    const [passworChange, setPasswordChange] = useState({
        current_password: "",
        new_password: "",
        confirm_new_password: ""
    })

    const handleSubmitUTM = () => {
        const data = {
            index: 1,
            setting_id: process.env.REACT_APP_API_SETTING_ID,
            event_UTM: eventutm,
            ads_UTM: adsutm
        }
        update_setting(id, data, token).then(data => {
            if (data.error) {
                toast.error(data.error, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        })
    }

    const handleSubmitFooter = () => {
        const data = {
            index: 2,
            setting_id: process.env.REACT_APP_API_SETTING_ID,
            whats_app_number: footerData.phone_number,
            instagram_link: footerData.intsagram,
            linkedin_link: footerData.linkedin,
            email_id: footerData.email,
        }
        update_setting(id, data, token).then(data => {
            if (data.error) {
                toast.error(data.error, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        })
    }

    const handleSubmitProfile = () => {
        // console.log("Profile");
        const data = {
            userId: id,
            first_name: userData.firstName,
            last_name: userData.lastName,
            phoneNumber: userData.phoneNumber
        }
        update_profile(data, token).then(data => {
            if (data.error) {
                toast.error(data.error, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        })
    }

    const handleSubmitChangePassword = () => {
        const data = {
            userId: id,
            current_password: passworChange.current_password,
            new_password: passworChange.new_password,
            confirm_new_password: passworChange.confirm_new_password,
        }
        update_profile_password(data, token).then(data => {
            if (data.error) {
                toast.error(data.error, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        })
    }

    const handleSubmitFooterContent = () =>{
        const data = {
            index: 4,
            setting_id: process.env.REACT_APP_API_SETTING_ID,
            footer_content: editorNote,
        }
        update_setting(id, data, token).then(data => {
            if (data.error) {
                toast.error(data.error, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        })
    }





    const handleSubmitWhiteStrip = () =>{
        const data = {
            index: 4,
            setting_id: process.env.REACT_APP_API_SETTING_ID,
            copy_right: copyRight,
        }
        update_setting(id, data, token).then(data => {
            if (data.error) {
                toast.error(data.error, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        })
    }


    const [allUsers, setAllUsers] = useState()




    const init = async () => {
        console.log(id);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/staff/get-staff-by-id?staffId=${id}`);
        const data = await response.json();
        setAllUsers(data);

        await fetch_setting(process.env.REACT_APP_API_SETTING_ID, token).then(data => {
            if (data.error) {
                console.log(data.error);
            }
            else {
                setEventutm(data.event_UTM)
                setAdsutm(data.ads_UTM)

                setEditorNote(data.footer_content)

                const blocksFromHTML_FooterContent = convertFromHTML(data.footer_content);
                const contentState_FooterContent = ContentState.createFromBlockArray(blocksFromHTML_FooterContent);
                const initialEditorState_FooterContent = EditorState.createWithContent(contentState_FooterContent);
                setEditorState(initialEditorState_FooterContent);

                const blocksFromHTML_Copyright = convertFromHTML(data.copy_right);
                const contentState_Copyright = ContentState.createFromBlockArray(blocksFromHTML_Copyright);
                const initialEditorState_Copyright = EditorState.createWithContent(contentState_Copyright);
                setCopyRightState(initialEditorState_Copyright);

                setCopyRight(data.copy_right)
                setFooterData({
                    ...footerData,
                    phone_number: data.whats_app_number,
                    linkedin: data.linkedin_link,
                    email: data.email_id,
                    intsagram: data.instagram_link
                })
                console.log(data);
            }
        })

    }

    // console.log(allUsers);
    const profiledata = async () => {
        const data = allUsers;
        console.log(data);
        setUsearData({
            ...userData,
            firstName: data?.name.firstName,
            lastName: data?.name.lastName,
            email: data?.email,
            phoneNumber: data?.phoneNumber,
        })
    }


    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (allUsers) {
            profiledata()
        }
    }, [allUsers])

    // console.log(userData);
    // console.log(allUsers);
    const [permission, setPermission] = useState()
    const [role, setRole] = useState()
    const fetch_user_data = () => {
        get_staff_by_id(id).then(data => {
            if (data.error) { console.log(data.error); }
            else { console.log(data); setPermission(data.permission); setRole(data.tag) }
        })
    }
    useEffect(() => {
        fetch_user_data()
    }, [])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true);

        const timeout = setTimeout(() => {
            setLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, [role])

    const [open, setOpen] = useState(false);
    const handleDeleteExpired = () => {
        delete_the_expire_event().then(data => {
            if (data.error) {
                window.alert(data.error)
            }
            else {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            setOpen(false)
        })
    }



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <>
            {loading ? <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin size='large' />

            </div> :
                <>


                    <Box sx={{ width: '100%' }}>
                        <div style={{ borderBottom: 1, borderColor: 'divider', overflowX: 'auto', width: '100%' }}>
                            <Tabs value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                sx={{ overflowX: 'auto', width: '100%' }}
                                variant="scrollable" // Add variant="scrollable" to enable scrolling
                                scrollButtons="auto" // Add scrollButtons="auto" to display scroll buttons when necessary
                            >
                                <Tab label="UTM Parameter" {...a11yProps(0)} />
                                <Tab label="Footer" {...a11yProps(1)} />
                                <Tab label="Profile" {...a11yProps(2)} />
                                <Tab label="Password" {...a11yProps(3)} />
                            </Tabs>
                        </div>
                        {/* UMT Section  */}
                        <TabPanel value={value} index={0} >
                            {role && role.toString() === process.env.REACT_APP_API_ADMIN_ID ?
                                <>
                                    <form className={Style.umt}>
                                        <div className={`${Style.commonGap}`} style={{ display: 'flex', gap: '10px' }} >
                                            <p style={{ width: '180px', display: 'flex', justifyContent: 'space-between' }} >Listings UTM<span>:</span></p>
                                            <TextField className='footerText' value={eventutm} onChange={(e) => { setEventutm(e.target.value) }} id="outlined-basic-1" placeholder='Enter Event UTM' variant="outlined" />
                                        </div>
                                        <div className={`${Style.umtAds} ${Style.commonGap}`} style={{ display: 'flex', gap: '10px' }} >
                                            <p style={{ width: '180px', display: 'flex', justifyContent: 'space-between' }} >Ads UTM <span>:</span> </p>
                                            <TextField className='footerText' value={adsutm} onChange={(e) => { setAdsutm(e.target.value) }} id="outlined-basic-1" placeholder='Enter Ads UTM' variant="outlined" />
                                        </div>
                                        <Button onClick={handleSubmitUTM} className='buttonHover' sx={{ background: '#1976d2', color: 'white', width: '180px' }}>Save Changes</Button>

                                    </form>
                                    <Divider />
                                    <h3>Delete Expired Listings </h3>
                                    <p style={{ padding: "15px 0" }}>Note: All listings will be deleted except the ones added in last 3 months. Newsletter history section will not be impacted by this deletion.</p>
                                    <Button className='buttonHover' sx={{ background: 'red', color: 'white', width: '180px' }} onClick={handleClickOpen}>Delete</Button>
                                </>
                                :
                                <Result
                                    status="403"
                                    title="403"
                                    subTitle="Sorry, you are not authorized to access this page."
                                />
                            }
                        </TabPanel >
                        {/* Footer Section  */}
                        < TabPanel value={value} index={1} >
                            {role && role.toString() === process.env.REACT_APP_API_ADMIN_ID ?
                                <form className={Style.footer}>
                                    <div className={`${Style.commonGap}`} style={{ display: 'flex', gap: '10px' }} >
                                        <p style={{ width: '180px', display: 'flex', justifyContent: 'space-between' }} >WhatsApp Number<span>:</span> </p>
                                        <TextField value={footerData.phone_number} id="outlined-basic-1" placeholder="Enter Number" variant="outlined" onChange={(e) => { setFooterData({ ...footerData, phone_number: e.target.value }) }} />
                                    </div>
                                    <div className={`${Style.commonGap}`} style={{ display: 'flex', gap: '10px' }} >
                                        <p style={{ width: '180px', display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap' }} >Instagram Acount Link<span>:</span> </p>
                                        <TextField value={footerData.intsagram} id="outlined-basic-1" placeholder="Enter Instagram Account Link" variant="outlined" onChange={(e) => { setFooterData({ ...footerData, intsagram: e.target.value }) }} />
                                    </div>
                                    <div className={`${Style.commonGap}`} style={{ display: 'flex', gap: '10px' }} >
                                        <p style={{ width: '180px', display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap' }} >LinkedIn Account Link<span>:</span> </p>
                                        <TextField value={footerData.linkedin} id="outlined-basic-1" placeholder="Enter Linkedin Account Link" variant="outlined" onChange={(e) => { setFooterData({ ...footerData, linkedin: e.target.value }) }} />
                                    </div>
                                    <div className={`${Style.commonGap}`} style={{ display: 'flex', gap: '10px' }} >
                                        <p style={{ width: '180px', display: 'flex', justifyContent: 'space-between' }} >Email Id<span>:</span> </p>
                                        <TextField value={footerData.email} id="outlined-basic-1" placeholder="Enter Email Id" variant="outlined" onChange={(e) => { setFooterData({ ...footerData, email: e.target.value }) }} />
                                    </div>
                                    <Button onClick={handleSubmitFooter} className={`${Style.settingBtn} buttonHover`} sx={{ background: '#1976d2', color: 'white', width: '180px' }}>Save Changes</Button>
                                    <Divider />
                                    <div className={`${Style.commonGap}`} style={{ display: 'flex', gap: '20px', flexDirection: 'column', alignItems: 'normal' }} >
                                        <p style={{ width: '180px', display: 'flex', justifyContent: 'space-between' }} >Footer Content</p>
                                        <div className='footerText' style={{ borderBottom: '2px solid grey', background: '#F4F4F4' }} >
                                            <Editor
                                                editorState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={onEditorStateChange}
                                                className='editor'
                                            />
                                            <textarea
                                                disabled
                                                value={editorNote}
                                                maxLength="500"
                                                hidden
                                            />
                                        </div>
                                    </div>
                                    <Button onClick={handleSubmitFooterContent} className={`${Style.settingBtn} buttonHover`} sx={{ background: '#1976d2', color: 'white', width: '180px' }}>Save Changes</Button>
                                    <Divider />
                                    <div className={`${Style.commonGap}`} style={{ display: 'flex', gap: '20px', flexDirection: 'column' }} >
                                        <p style={{ width: '180px', display: 'flex', justifyContent: 'space-between' }} >White Strip Text</p>
                                        <div className='footerText' style={{ borderBottom: '2px solid grey', background: '#F4F4F4' }} >
                                            <Editor
                                                editorState={copyRightState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={onCopyRightStateChange}
                                            />
                                            <textarea
                                                disabled
                                                value={copyRight}
                                                maxLength="500"
                                                hidden
                                            />
                                        </div>
                                    </div>
                                    <Button onClick={handleSubmitWhiteStrip} className={`${Style.settingBtn} buttonHover`} sx={{ background: '#1976d2', color: 'white', width: '180px' }}>Save Changes</Button>
                                    <Divider />
                                </form>
                                :
                                <Result
                                    status="403"
                                    title="403"
                                    subTitle="Sorry, you are not authorized to access this page."
                                />
                            }
                        </TabPanel >
                        <TabPanel value={value} index={2} >
                            <form className={Style.umt}>
                                <div className={`${Style.commonGap}`} style={{ display: 'flex', gap: '10px' }} >
                                    <p style={{ width: '180px', display: 'flex', justifyContent: 'space-between' }} >First Name<span>:</span></p>
                                    <TextField value={userData.firstName} onChange={(e) => { setUsearData({ ...userData, firstName: e.target.value }) }} id="outlined-basic-1" placeholder='Enter Name' variant="outlined" />
                                </div>
                                <div className={`${Style.commonGap}`} style={{ display: 'flex', gap: '10px' }} >
                                    <p style={{ width: '180px', display: 'flex', justifyContent: 'space-between' }} >Last Name<span>:</span></p>
                                    <TextField value={userData.lastName} onChange={(e) => { setUsearData({ ...userData, lastName: e.target.value }) }} id="outlined-basic-1" placeholder='Enter Name' variant="outlined" />
                                </div>
                                <div className={`${Style.commonGap}`} style={{ display: 'flex', gap: '10px' }} >
                                    <p style={{ width: '180px', display: 'flex', justifyContent: 'space-between' }} >Email<span>:</span></p>
                                    <TextField value={userData.email} onChange={(e) => { setUsearData({ ...userData, email: e.target.value }) }} id="outlined-basic-1" placeholder='Enter Email' variant="outlined" disabled />
                                </div>
                                <div className={`${Style.commonGap}`} style={{ display: 'flex', gap: '10px' }} >
                                    <p style={{ width: '180px', display: 'flex', justifyContent: 'space-between' }} >Phone Number<span>:</span></p>
                                    <TextField value={userData.phoneNumber} onChange={(e) => { setUsearData({ ...userData, phoneNumber: e.target.value }) }} id="outlined-basic-1" placeholder='Enter Phone Number' variant="outlined" />
                                </div>
                                <Button onClick={handleSubmitProfile} className={`${Style.settingBtn} buttonHover`} sx={{ background: '#1976d2', color: 'white', width: '180px' }}>Save Changes</Button>

                            </form>
                        </TabPanel>

                        <TabPanel value={value} index={3} >
                            <form className={Style.umt}>
                                <div className={`${Style.umtAds} ${Style.commonGap}`} style={{ display: 'flex', gap: '10px' }} >
                                    <p style={{ width: '180px', display: 'flex', justifyContent: 'space-between' }} >Current Password<span>:</span> </p>
                                    <TextField value={passworChange.current_password} onChange={(e) => { setPasswordChange({ ...passworChange, current_password: e.target.value }) }} id="outlined-basic-1" placeholder='Enter Current Password' variant="outlined" type='password' />
                                </div>
                                <div className={`${Style.umtAds} ${Style.commonGap}`} style={{ display: 'flex', gap: '10px' }} >
                                    <p style={{ width: '180px', display: 'flex', justifyContent: 'space-between' }} >New Password<span>:</span> </p>
                                    <TextField value={passworChange.new_password} onChange={(e) => { setPasswordChange({ ...passworChange, new_password: e.target.value }) }} id="outlined-basic-1" placeholder='Enter New Password' variant="outlined" type='password' />
                                </div>
                                <div className={`${Style.umtAds} ${Style.commonGap}`} style={{ display: 'flex', gap: '10px' }} >
                                    <p style={{ width: '180px', display: 'flex', justifyContent: 'space-between' }} >Confirm New Password<span>:</span> </p>
                                    <TextField value={passworChange.confirm_new_password} onChange={(e) => { setPasswordChange({ ...passworChange, confirm_new_password: e.target.value }) }} id="outlined-basic-1" placeholder='Confirm New Password' variant="outlined" type='password' />
                                </div>
                                <Button onClick={handleSubmitChangePassword} className={`${Style.settingBtn} buttonHover`} sx={{ background: '#1976d2', color: 'white', width: '180px' }}>Save Changes</Button>

                            </form>
                        </TabPanel>
                        {/* <ToastContainer /> */}
                    </Box >


                </>}
            <ToastContainer />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure want to delete
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={handleDeleteExpired} >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>



    );
}