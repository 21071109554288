const url = process.env.REACT_APP_API_URL


export const fetchApi= async (id,noofdays,token) => {
    try {
        const response = await fetch(`${url}/dashboard-analytics/fetch?cityId=${id}&noofdays=${noofdays}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}
