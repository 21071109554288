const url = process.env.REACT_APP_API_URL

export const sendInvitation = (id,data,token) => {
    return (
        fetch(`${url}/staff/sendInvitation?staff=${id}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization : `Bearer ${token}`
            },
            body: JSON.stringify(data)
            // body: data
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}

export const listStaff = async (id,token) =>{
    try {
        const response = await fetch(`${url}/staff/list?staff=${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}


export const staffRegister = (data) => {
    return (
        fetch(`${url}/staff/register`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
            // body: data
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}

export const update_staff_admin = (id,data,token) => {
    return (
        fetch(`${url}/staff/update-staff-admin?staffId=${id}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization : `Bearer ${token}`
            },
            body: JSON.stringify(data)
            // body: data
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}



export const get_staff_by_id = async (id) =>{
    try {
        const response = await fetch(`${url}/staff/get-staff-by-id?staffId=${id}`, {
            method: 'GET'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}


export const deletStaff = async(id,user_id,token) =>{
    try{
        const response = await fetch(`${url}/staff/delete?id=${user_id}&staff=${id}`,{
            method:'DELETE',
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        const data = await response.json()
        return data
    }catch(error){
        return error
    }
}