import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const EmailAnalyticsChart = ({ graphData, locationId, noofdays }) => {

  console.log(
    graphData
  );
 
  
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={graphData}>
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="total_user" stroke="#32a84e" dot={false} name="Total Users" />
        <Line type="monotone" dataKey="news_letter_opened_count_unique_user" stroke="#1e05fc" dot={false} name="Email Opened Count" />
        <Line type="monotone" dataKey="event_click" stroke="#a83236" dot={false} name="Listing Clicks" />
        <Line type="monotone" dataKey="ad_click" stroke="#fc8105" dot={false} name="Ad Clicks" />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default EmailAnalyticsChart;
