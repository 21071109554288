const url = process.env.REACT_APP_API_URL


export const listEvent= async (id,filter,city_id,token) => {
    try {
        const response = await fetch(`${url}/event/list?staff=${id}&city=${city_id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            body:JSON.stringify(filter)
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}



export const deletEvent = async(id,event_id,token) =>{
    try{
        const response = await fetch(`${url}/event/delete?id=${event_id}&staff=${id}`,{
            method:'DELETE',
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        const data = await response.json()
        return data
    }catch(error){
        return error
    }
}

// update



export const Gmail_Page= async (id,cityId,newsletterId,template) => {
    try {
        const response = await fetch(`${url}/event/gmail-page?id=${id}&cityId=${cityId}&newsletterId=${newsletterId}&template=${template}`, {
            method: 'GET',
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}





export const Fetch_history_city_wise= async (id) => {
    try {
        const response = await fetch(`${url}/event/fetch-history-city-wise?city_id=${id}`, {
            method: 'GET'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}


export const delete_the_expire_event= async (id) => {
    try {
        const response = await fetch(`${url}/event/delete-the-expire-event`, {
            method: 'GET'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}


