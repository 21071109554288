const url = process.env.REACT_APP_API_URL

export const forgot_password_send_mail = (data) =>{
    return(
        fetch(`${url}/staff/password/fpsendmail`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}

export const forgot_password_change_password = (data) =>{
    return(
        fetch(`${url}/staff/password/fpchangepassword`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}