import React, { useEffect, useState } from 'react'
import { isAuthenticated } from '../../auth'
import { createLocation, deletLocation, editLocation, listLocation } from '../../controllers/location'
import Style from "../Location/Location.module.css"
import Table from "../../components/Tables/Table"
import RightSlide from './RightSlide/RightSlide'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Time from 'react-time-format'
import IconButton from '@mui/material/IconButton';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { get_staff_by_id } from '../../controllers/staff'
import { Result, Spin } from 'antd';
import { listAd } from '../../controllers/ad'

const Location = () => {

    const [location, setlocation] = useState('')
    const [locations, setlocations] = useState([])
    const [locationId, setLocationId] = useState()
    const [edit, setEdit] = useState(false)
    const [refersh, setRefresh] = useState(true)
    const handleChange = (e) => {
        setlocation(e.target.value)
    }

    const { token, id } = isAuthenticated();
    const [permission, setPermission] = useState()
    const fetch_user_data = () => {
        get_staff_by_id(id).then(data => {
            if (data.error) { console.log(data.error); }
            else { setPermission(data.permission) }
        })
    }

    useEffect(() => {
        fetch_user_data()
    }, [])
    //  for adding location
    const handleSubmit = (e) => {
        e.preventDefault()
        if (edit === false) {
            const data = {
                locationName: location,
                // createdBy:"",
            }
            createLocation(id, data, token)
                .then(data => {
                    if (data.error) {
                        console.log("error : ", data.error);
                    } else {
                        console.log("location added successfully")
                    }
                })
        } else {
            const data = {
                locationName: location,
                // createdBy:"",
            }
            editLocation(id, data, locationId, token).then(data => {
                if (data.error) {
                    console.log("error : ", data.error);
                } else {
                    window.alert("location Updated successfully")
                }
            }).catch(error => console.error(error))
        }
    }




    // for deleting location

    const handleDelete = (location_id) => {
        deletLocation(id, location_id, token).then(data => {
            if (data.message) {
                handleClose()
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } if (data.error) {
                toast.error(data.error, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            init();
        })
    }


    


    // for listing all the locations & ads

    const init = () => {
        listLocation(id, token).then(data => {
            console.log(data)
            setlocations(data)
        }).catch(error => console.error(error));
    };

    useEffect(() => {
        init();
    }, [refersh]);



    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteid] = useState()

    const handleClickOpen = (id) => {
        setDeleteid(id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    // Table Content 
    const TableHeading = ['Loaction Name', "Created By", 'Created On', 'Ads Running',  'Actions']

    const Keys = ['locationName', "createdBy", 'createdAt', "ads running","_id"]

    const TableData = locations.length > 0 && locations?.map((item) => Keys.map((Key) => {
        if (Key === "_id") {
            return <>
            {permission && permission.location.edit === 1 && <RightSlide refersh={refersh} locations={locations} setRefresh={setRefresh} itemId={item?._id} CheckValue={2} />}
            {permission && permission.location.delete === 1 && <span className='hoverIcon'><IconButton><DeleteOutlineOutlinedIcon onClick={() => handleClickOpen(item._id)} /></IconButton></span>}
            </>
        }
        if (Key === "ads running") {
            return item?.locationAds > 0 ? item?.locationAds : 0
        }
        if (Key === 'createdAt') {
            return <Time value={item.createdAt} format="DD-MM-YYYY" />
        }
        if (Key === 'createdBy') {
            console.log(item.createdBy)
            return `${item.createdBy?.name?.firstName} ${item.createdBy?.name?.lastName}`
        }
        else {
            return item[Key]
        }
    }
    ))

    // console.log("locations", locations)


    const [loading, setLoading] = useState(true)

    useEffect(() => {
      setLoading(true);
  
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 500);
  
      return () => clearTimeout(timeout);
    }, [permission])
  


    return (
        <>
        
      {loading ? <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Spin size='large' />

      </div> :
        <>
         {permission && permission.location.read === 1 ?
                <>

                    <div className={Style.location}>
                        <div className={Style.locationHead}>
                            <p className='headDetail' >
                                <h3>Locations</h3>
                                <span className='totalNumbers' >Total Locations : {locations.length}</span>
                            </p>
                            {permission && permission.location.create === 1 && <RightSlide refersh={refersh} setRefresh={setRefresh} CheckValue={1} onSubmit={handleSubmit} onChange={handleChange} />}
                        </div>

                        {locations?.length !== 0 ?
                            <>
                                <div className={Style.locationTable}>
                                    <Table TableData={TableData} TableHeading={TableHeading} ></Table>
                                </div>
                            </>
                            :
                            <>
                                No Locations Available
                            </>
                        }

                    </div>


                </>
                :
                <Result
                    status="403"
                    title="403"
                    subTitle="Sorry, you are not authorized to access this page."
                />
            }
         </> }
            
            <ToastContainer />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure want to delete this location
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button sx={{ background: "rgba(91, 141, 215, 1) !important", color: "white" }} onClick={() => handleDelete(deleteId)} >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Location
