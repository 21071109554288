import React from 'react'

const NotFound = () => {
  return (
    <div style={{width:"100%",height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
     404 Not Found
    </div>
  )
}

export default NotFound
