const url = process.env.REACT_APP_API_URL

export const createLocation= (id,data,token) => {
    return (
        fetch(`${url}/location/create?staff=${id}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization : `Bearer ${token}`
            },
            body: JSON.stringify(data)
            // body: data
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}


export const listLocation= async (id,token) => {
    try {
        const response = await fetch(`${url}/location/list?staff=${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}



export const editLocation= async(id,data,location_id,token)=>{
    return(
        fetch(`${url}/location/update?id=${location_id}&staff=${id}`, {
            method: "PUT",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization : `Bearer ${token}`
            },
            body: JSON.stringify(data)
            // body: data
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}



export const deletLocation= async(id,location_id,token) =>{
    try{
        const response = await fetch(`${url}/location/delete?staff=${id}&id=${location_id}`,{
            method:'DELETE',
            headers:{
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        const data = await response.json()
        return data
    }catch(error){
        return error
    }
}














export const fetch_location_weekendo_website= async () => {
    try {
        const response = await fetch(`${url}/location/fetch-location-weekendo-website`, {
            method: 'GET'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}