import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Style from "./CategoryTable.module.css"
import Button from '@mui/material/Button';
import { shuffle_category } from '../../../controllers/category';
import { isAuthenticated } from '../../../auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App({ shuffleData }) {
    const [rows, setRows] = useState(shuffleData);
    const { token, id } = isAuthenticated();
    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const reorderedRows = Array.from(rows);
        const [removed] = reorderedRows.splice(result.source.index, 1);
        reorderedRows.splice(result.destination.index, 0, removed);

        setRows(reorderedRows);
    };

    const handleUpdate = () => {
        const order = rows.map((item) => item.id)
        const data = {
            id:process.env.REACT_APP_API_SUFFLE_CATEGORY_ORDER,
            order: order
        }
        shuffle_category(id, data, token).then(data => {
            console.log(data);
            if (data.error) {
                console.log(data.error);
            }
            if(data.message) {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        })
    }


    // console.log(rows);
    return (
      
            <div className={Style.grandParent}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3 style={{ opacity: '0.8' }} >Drag The Category</h3>
                    <Button variant="outlined" onClick={handleUpdate} sx={{ width: 'max-content', height: '36.5px' }} >
                        Save
                    </Button>
                </div>
                <div className={Style.parent} >
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className={Style.parentHead} style={{}}>
                            Category Name
                        </div>
                        <Droppable droppableId="rows">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {rows && rows.map((row, index) => (
                                        <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div className={Style.parentRow} >{row.categoryName}</div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
          
     
    );
}

export default App;
