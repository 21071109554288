import React, { useEffect, useState } from 'react'
import { createUser, createUserWeekendoWebsite } from '../../../controllers/user';
import { fetch_category_weekendo_website, listCategory } from '../../../controllers/category';
import { fetch_location_weekendo_website, listLocation } from '../../../controllers/location';
import Select from 'react-select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Style from "./Form.module.css";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';



const UserRegistrationForm = () => {

    const [Categorys, setCategorys] = useState([])
    const [Locations, setLocations] = useState([])
    const [selectedCities, setSelectedCities] = useState([]);
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        userEmail: '',
        phoneNumber: '',
        gender: '',
        city: [],
        dateOfBirth: "",
        category: [process.env.REACT_APP_API_COLLAB_ID],
        jobType: '',
        companyName: ''
    })


    const [gender, setGender] = useState('');

    const init = async () => {
        try {
            const categoryData = await fetch_category_weekendo_website();
            setCategorys(categoryData);

            const locationData = await fetch_location_weekendo_website();
            setLocations(locationData);
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        init()
    }, [])


    const handleGenderChange = (event) => {
        setGender(event.target.value);
        setUserData({ ...userData, gender: event.target.value })
    };

    const handleValue = (event) => {
        const { name, value } = event.target;
        const minBirthDate = new Date();
        minBirthDate.setFullYear(minBirthDate.getFullYear() - 18);
        const minBirthDateString = minBirthDate.toISOString().split('T')[0];

        if (event.target.name === 'dateOfBirth' && event.target.value > minBirthDateString) {
            return;
        }
        setUserData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleCityChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setSelectedCities(selectedValues);
        setUserData({
            ...userData,
            city: selectedValues
        })
    };

    const cityOptions = Locations?.map((data) => ({
        value: data?._id,
        label: data?.locationName,
    }));

    const handleCheckboxChange = (event) => {
        const { name, value, checked } = event.target;
        if (checked) {
            setUserData((prevFormData) => ({
                ...prevFormData,
                [name]: [...prevFormData[name], value]
            }));
        } else {
            setUserData((prevFormData) => ({
                ...prevFormData,
                [name]: prevFormData[name].filter((item) => item !== value)
            }));
        }
    };

    const [emptyFieldError, setEmptyFieldError] = useState()
    const [registartionSuccess, setRegistartionSuccess] = useState()
    const handleFormSubmit = (event) => {
        event.preventDefault();
        // console.log(userData);
        if (
            userData.firstName === '' ||
            userData.lastName === '' ||
            userData.userEmail === '' ||
            userData.phoneNumber === '' ||
            userData.gender === '' ||
            userData.city.length === 0 ||
            userData.dateOfBirth === ""
        ) {
            setEmptyFieldError("All field are required")
            return
        }

        if (userData.category.length < 6) {
            setEmptyFieldError("Select atleast 5 categories")
            return
        }

        createUserWeekendoWebsite(userData)
            .then(data => {
                if (data.error) {
                    setEmptyFieldError(data.error)
                } else {
                    setRegistartionSuccess(data.message)
                }
            })


    }

    const getMinBirthDate = () => {
        const minBirthDate = new Date();
        minBirthDate.setFullYear(minBirthDate.getFullYear() - 18);
        return minBirthDate.toISOString().split('T')[0];
    };
    
    return (
        <div>
            <form className={Style.form} onSubmit={handleFormSubmit}>
                <div className={Style.formUpper}>
                    <h3>Registrer Me For FREE!!!</h3>
                    <div className={Style.formInputs} >
                        <div className={Style.formInputs_section}>
                            <TextField className={Style.formInput} value={userData.firstName} id="outlined-basic-1" name='firstName' label="Enter First Name" variant="standard" onChange={handleValue} />
                            <TextField className={Style.formInput} value={userData.lastName} id="outlined-basic-2" name='lastName' label='Enter Last Name' variant="standard" onChange={handleValue} />
                            <div>
                                <Select
                                    placeholder={"Select City"}
                                    name="city"
                                    styles={{ border: "none !important" }}
                                    value={cityOptions.filter(option => selectedCities.includes(option.value))}
                                    options={cityOptions}
                                    isMulti
                                    onChange={handleCityChange} />
                            </div>

                        </div>
                        <div className={Style.formInputs_section}>
                            <TextField className={Style.formInput} value={userData.phoneNumber} id="outlined-basic-4" name='phoneNumber' label='Enter Phone Number' variant="standard" onChange={handleValue} />
                            <TextField className={Style.formInput} value={userData.jobType} id="outlined-basic-5" name='jobType' label='Enter Job Type' variant="standard" onChange={handleValue} />
                            {/* <div className={Style.formInput} >
                                <label>Date of Birth :</label>
                                <input style={{ width: "max-content " }} value={userData.dateOfBirth} type='date' name='dateOfBirth' placeholder='Enter Date of Birth' onChange={handleValue} />
                            </div> */}
                            <div className={Style.formInput}>
                                <label>Date of Birth:</label>
                                <input
                                    style={{ width: "max-content" }}
                                    value={userData.dateOfBirth}
                                    type="date"
                                    name="dateOfBirth"
                                    placeholder="Enter Date of Birth"
                                    onChange={handleValue}
                                    min={getMinBirthDate()}
                                />
                            </div>

                        </div>
                        <div className={Style.formInputs_section}>
                            <TextField className={Style.formInput} value={userData.userEmail} id="outlined-basic-3" name='userEmail' label='Enter Email' variant="standard" onChange={handleValue} />
                            <TextField className={Style.formInput} value={userData.companyName} id="outlined-basic-6" name='companyName' label='Enter Company Name' variant="standard" onChange={handleValue} />
                            <div className={`${Style.genderSelect}`}  >
                                <label style={{ fontSize: "18px" }}>Gender :</label>
                                <div style={{ display: 'flex' }} >
                                    {/* <label style={{ display: 'flex', alignItems: "center", gap: '10px' }} >
                                        <input style={{ height: "15px", width: "max-content" }}
                                            type="radio"
                                            name="gender"
                                            value="male"
                                            checked={gender === "male"}
                                            onChange={handleGenderChange}
                                        /> <p>Male</p>
                                        
                                    </label> */}
                                    <FormControlLabel name="gender"
                                        value="male"
                                        checked={gender === "male"}
                                        onChange={handleGenderChange} control={<Checkbox />} label="Male" sx={{ width: "100%" }} />

                                    <FormControlLabel name="gender"
                                        value="female"
                                        checked={gender === "female"}
                                        onChange={handleGenderChange} control={<Checkbox />} label="Female" sx={{ width: "100%" }} />

                                    {/* <label style={{ display: 'flex', alignItems: "center", gap: '10px' }} >
                                        <input style={{ height: "15px", width: "max-content" }}
                                            type="radio"
                                            name="gender"
                                            value="female"
                                            checked={gender === "female"}
                                            onChange={handleGenderChange}
                                        /> <p>Female</p>
                                    </label> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={Style.fromLower}>
                    <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }} >
                        <h4>Select Categories</h4>
                        <span style={{ fontSize: '13px', opacity: '0.8' }} >(Select minimum five)</span>
                    </div>
                    <div className={Style.formCategories} >
                        {Categorys && Categorys.map((data, index) => (
                            <>
                                {data._id !== process.env.REACT_APP_API_COLLAB_ID &&
                                    <p style={{ display: 'flex', fontSize: "17px", gap: '10px', alignItems: "center", paddingBottom: "10px" }} >
                                        <input name='category' type='checkbox' value={data._id} onChange={handleCheckboxChange} checked={userData.category.includes(data._id)} />
                                        {data.categoryName}

                                    </p>}
                            </>
                        ))}
                    </div>
                </div>
                {registartionSuccess && <p style={{ color: "green", fontSize: "14px" }}>{registartionSuccess}</p>}
                {emptyFieldError && <p style={{ color: "red", fontSize: "14px" }}>{emptyFieldError}</p>}
                <Button variant="contained" type='submit' style={{ background: 'rgba(125, 206, 19, 1)', padding: '5px 25px', borderRadius: '20px' }} >I AM IN!</Button>
                {/* <Button variant="contained" type='submit' className={`${Style.button} buttonHover`} style={{ backgroundColor: '#1976d2 !important', marginTop: '10px' }} >I Am In!</Button> */}
                <div className={Style.bottomLeft} style={{ background: `url(${process.env.PUBLIC_URL}/Assets/Form/BottomLeft.svg)` }}></div>
                <div className={Style.topRight} style={{ background: `url(${process.env.PUBLIC_URL}/Assets/Form/TopRight.svg)` }}></div>
            </form>
        </div>
    )
}

export default UserRegistrationForm