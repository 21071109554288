import React, { useEffect, useState } from 'react'
// import Navbar from '../../components/SideBar/SideBar'
import { isAuthenticated } from '../../auth'
import { change_category_isactive_status, createCategory, deletCategory, editCategory, listCategory } from '../../controllers/category'
import Table from "../../components/Tables/Table"
import RightSlide from "./RightSlide/RightSlide"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import Style from "./category.module.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircleIcon from '@mui/icons-material/Circle';
import Time from 'react-time-format'
import IconButton from '@mui/material/IconButton';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FilterCategorybutton from './filter'
import { listLocation } from '../../controllers/location'
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import CategoryTable from "./CategoryTable/CategoryTable"
import { get_staff_by_id } from '../../controllers/staff'
import { Result, Spin } from 'antd';


const Category = () => {
    const [Categorys, setCategorys] = useState([])
    const [Check, setCheck] = useState(false)

    const [filterUser, setFilteruser] = useState(false)
    const [filterCity, setFilterCity] = useState([])
    const { token, id } = isAuthenticated();
    const [refersh, setRefresh] = useState(true)
    const [Locations, setLocations] = useState([])

    const [drag, setDrag] = useState(false)








    const [permission, setPermission] = useState()
    const [role, setRole] = useState()
    const fetch_user_data = () => {
        get_staff_by_id(id).then(data => {
            if (data.error) { console.log(data.error); }
            else { setPermission(data.permission); setRole(data.tag) }
        })
    }

    useEffect(() => {
        fetch_user_data()
    }, [])








    // for deleting Category
    const handleDelete = (category_id) => {

        deletCategory(id, category_id, token).then(data => {
            if (data.message) {
                handleClose()
                toast.success('Category Deleted successfully', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setCheck(!Check)
            } if (data.error) {
                toast.error(data.error, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        })

    }

    const handleActiveStatus = (category_id) => {

        change_category_isactive_status(id, category_id, token).then(data => {
            if (data.message) {
                toast.success('Category Status Updated successfully', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setCheck(!Check)
            }
        })
        setOpen1(false);
    }

    // for listing all the Categorys
    const init = async () => {
        const locationData = await listLocation(id, token);
        setLocations(locationData);

        const filter = {
            id: process.env.REACT_APP_API_SUFFLE_CATEGORY_ORDER,
            city: filterCity
        }

        listCategory(id, filter, token).then(data => {
            console.log(data)
            setCategorys(data)
        }).catch(error => console.error(error));

    };

    // console.log(Categorys);

    useEffect(() => {
        init();
    }, [Check, refersh, filterUser, drag]);



    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteid] = useState()

    const handleClickOpen = (id) => {
        setDeleteid(id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [open1, setOpen1] = useState(false);
    const [updateStatusId, setUpdateStatusId] = useState()
    const [text, setText] = useState()
    const handleClickOpen1 = (id, text) => {
        setUpdateStatusId(id)
        setText(text)
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    // Table Content 

    const TableHeading = ['Category Name', 'Users', "Created By", 'Category Color', <p style={{ textAlign: 'right', fontWeight: '300' }}>Created On</p>, 'Actions'];

    const Keys = ['categoryName', 'userCount', "createdBy", 'color', 'createdAt', '_id']

    const TableData = Categorys.map((item) => Keys.map((Key) => {
        if (Key === 'createdAt') {
            return <p style={{ textAlign: 'right', fontWeight: '300' }}><Time value={item.createdAt} format="DD-MM-YYYY" /></p>
        }

        if (Key === 'categoryName') {
            return (
                <span style={{ display: "flex", alignItems: "center" }}>
                    {item.categoryName}&nbsp;&nbsp;<IconButton><Tooltip title={item.description}><HelpOutlineIcon sx={{ color: "rgba(91, 141, 215, 1) !important", cursor: "pointer", fontSize: "20px" }} /></Tooltip></IconButton>
                </span>
            )
        }
        if (Key === '_id') {
            return <>
                {permission && permission.category.edit === 1 && <>

                    {item?.isActive === true ? (
                        <IconButton>
                            <CircleIcon
                                onClick={() => { handleClickOpen1(item?._id, "Are you sure you want to make this category Inactive"); }}
                                sx={{ color: "green" }}
                            />
                        </IconButton>
                    ) : (
                        <IconButton>
                            <CircleIcon
                                onClick={() => { handleClickOpen1(item?._id, "Are you sure you want to make this category Inactive"); }}
                                sx={{ color: "red" }}
                            />
                        </IconButton>
                    )}
                </>}

                {permission && permission.category.edit === 1 && <RightSlide Categorys={Categorys} refersh={refersh} setRefresh={setRefresh} itemId={item?._id} CheckValue={2} />}
                {permission && permission.category.delete === 1 &&
                    <span className='hoverIcon'>
                        <IconButton><DeleteOutlineOutlinedIcon onClick={() => handleClickOpen(item._id)} /></IconButton>
                    </span>
                }

            </>
        }
        if (Key === 'color') {
            return <>
                <div style={{ backgroundColor: item[Key], height: '20px', }}></div>
            </>
        }
        if (Key === 'createdBy') {
            console.log(item.createdBy)
            return `${item.createdBy?.name?.firstName} ${item.createdBy?.name?.lastName}`
        }
        else {
            return item[Key] || ""
        }

    }
    ))



    const shuffleData = Categorys.map((item) => {

        return {
            "id": item._id,
            "categoryName": item.categoryName
        }

    })

    // console.log(shuffleData);

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true);

        const timeout = setTimeout(() => {
            setLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, [permission])

    return (
        <>

            {loading ? <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin size='large' />

            </div> :
                <>
                    {permission && permission.category.read === 1 ?
                        <>
                            <div className={Style.categorys}>
                                <div className={Style.categorysHead}>
                                    <p className='headDetail' >
                                        <h3>Categories</h3>
                                        <span className='totalNumbers' >Total Categories : {Categorys.length}</span>
                                    </p>
                                    <div className={Style.topButton} style={{ display: "flex", gap: '10px' }}>
                                        <FilterCategorybutton
                                            filterCity={filterCity}
                                            setFilteruser={setFilteruser}
                                            filterUser={filterUser}
                                            setFilterCity={setFilterCity}
                                            Locations={Locations}
                                        />
                                        {role && role.toString() === process.env.REACT_APP_API_ADMIN_ID &&
                                            <Button className='drag' variant="outlined" onClick={() => (setDrag(!drag))} sx={{ width: 'max-content', height: '36.5px' }} >
                                                {drag === false ? "Enable Drag" : "Disable Drag"}
                                            </Button>
                                        }

                                        {permission && permission.category.create === 1 && <RightSlide refersh={refersh} setRefresh={setRefresh} CheckValue={1} />}

                                    </div>

                                </div>
                                {Categorys?.length !== 0 ?
                                    <>
                                        {drag === false ? <>
                                            <div className={Style.categorysTable}>
                                                <Table TableData={TableData} TableHeading={TableHeading} />
                                            </div>
                                        </> : <>
                                            <div className="">
                                                <CategoryTable shuffleData={shuffleData} />
                                            </div>
                                        </>}
                                    </>
                                    :
                                    <>
                                        No Category Available
                                    </>
                                }

                            </div>
                        </>
                        :
                        <Result
                            status="403"
                            title="403"
                            subTitle="Sorry, you are not authorized to access this page."
                        />
                    }
                </>}

            <ToastContainer />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure want to delete this category
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button  variant="contained" onClick={() => handleDelete(deleteId)} >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open1}
                onClose={handleClose1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose1}>Cancel</Button>
                    <Button variant="contained" onClick={() => handleActiveStatus(updateStatusId)} >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Category
