const url = process.env.REACT_APP_API_URL

export const update_setting = (id,data,token) => {
    return (
        fetch(`${url}/setting/update?staff=${id}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization : `Bearer ${token}`
            },
            body: JSON.stringify(data)
            // body: data
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}


export const fetch_setting = (settingId,token) => {
    return (
        fetch(`${url}/setting/fetch-setting?settingId=${settingId}`, {
            method: "GET",
            headers: {
                Authorization : `Bearer ${token}`
            },
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}

export const update_profile = (data,token) => {
    return (
        fetch(`${url}/setting/update-profile`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization : `Bearer ${token}`
            },
            body: JSON.stringify(data)
            // body: data
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}

export const update_profile_password = (data,token) => {
    return (
        fetch(`${url}/setting/update-profile-password`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization : `Bearer ${token}`
            },
            body: JSON.stringify(data)
            // body: data
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}

