import React, { useEffect, useState } from 'react'
import Style from "./History.module.css"
import Table from '../../components/Tables/Table'
import Button from '@mui/material/Button';
import HistoryIcon from '@mui/icons-material/History';
import { listLocation } from '../../controllers/location';
import { isAuthenticated } from '../../auth';
import { Fetch_history_city_wise } from '../../controllers/newsletter';
import Time from 'react-time-format'
import GmailPageOne from "../GmailPage/GmailPageOne/GmailPageOne"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import GmailPageFour from '../GmailPage/GmailPageFour/GmailPageFour';
import GmailPageTwo from '../GmailPage/GmailPageTwo/GmailPageTwo';
import GmailPageThree from '../GmailPage/GmailPageThree/GmailPageThree';
import { Spin } from 'antd';



const History = () => {

    const [show, setShow] = useState(1)
    const { token, id, permission } = isAuthenticated();
    const [locationData, setLocationData] = useState([])
    const [check, setCheck] = useState(false);
    const [refersh, setRefresh] = useState(true)
    const [cityName, setcityname] = useState()
    const [ciityId, setCityId] = useState()
    const [historyData, setHistoryData] = useState([])

    const init = async () => {
        listLocation(id, token).then(data => {
            setLocationData(data)
        }).catch(error => console.error(error));
    };
    useEffect(() => {
        init();
    }, [check, refersh]);


    const handleView = async (city_id, city_name) => {

        Fetch_history_city_wise(city_id).then(data => {
            setHistoryData(data)
        }).catch(error => console.error(error));

        // console.log(city_name);

        // console.log(ads);
        setcityname(city_name)
        setCityId(city_id)
        setShow(2)
    }

    // console.log(historyData);


    const TableHeading = ['City', 'View']
    const keys = ['locationName', '_id']
    const TableData = locationData.length > 0 && locationData.map(item => keys.map(key => {
        if (key === "_id") {
            return <>
                <Button className='addBtn' variant="contained" onClick={() => handleView(item?._id, item.locationName)} >View</Button>
            </>
        }
        else {
            return item[key] || "";
        }
    })
    );

    const [previewData, setPreviewData] = useState()
    const [template, setTemplate] = useState()
    const handlePreview = (id) => {
        const x = historyData.filter(data => data._id === id)
        // console.log(x);
        const data = {
            editorNote: x[0].editor_note,
            events: x[0].events_included_data,
            ad: x[0].ads_included_data,
            footerData: x[0].footerData
        }
        setTemplate(x[0].template_no)
        setPreviewData(data)
        setShow(3)
    }
    const [reverse, setreverse] = useState([])

    const reverseArray = () => {
      const x = historyData.length > 0 ? historyData?.reverse() : []
      setreverse(x)
    }
  
    useEffect(() => {
      reverseArray()
    }, [historyData])

    const TableHeadingTwo = ['Title', 'Targeted Audience', 'Sent By', 'Sent Date', 'Action']
    const keysTwo = ['title', 'target_audience', 'sent_by', 'createdAt', '_id']
    const TableDataTwo = reverse.length > 0 && reverse.map(item => keysTwo.map(key => {
        if (key === "_id") {
            return <>
                <Button className='addBtn' variant="contained" onClick={() => handlePreview(item?._id)} >Preview</Button>
            </>
        }
        if (key === 'sent_by') {
            return `${item.sent_by.name}`
        }
        if (key === 'createdAt') {
            return <Time value={item.createdAt} format="DD-MM-YYYY" />
        }
        else {
            return item[key] || "";
        }
    })
    );

    // console.log(historyData)


    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(true);

        const timeout = setTimeout(() => {
            setLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, [show])




    return (
        <>


            {loading ?
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin size='large' />

                </div> :

                <>

                    {show === 1 && <>

                        <div className={Style.history}>
                            <div className={Style.history_head}>
                                <h3>History</h3>
                            </div>

                            <div className={Style.history_table}>
                                <Table TableData={TableData} TableHeading={TableHeading} />
                            </div>
                        </div>
                    </>
                    }</>}









            {loading ?
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin size='large' />

                </div> :

                <>
                    {show === 2 && <>

                        <div className={Style.history}>
                            <div className={Style.history_head}>
                                <h3> <span onClick={() => setShow(1)} style={{ color: '#1976d2', cursor: 'pointer' }} >{cityName}</span> &gt; Mail Sent</h3>
                            </div>

                            <div className={Style.history_table}>

                                <Table TableData={TableDataTwo} TableHeading={TableHeadingTwo} />

                            </div>
                        </div>
                    </>}
                </>}




            {loading ?
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin size='large' />

                </div> :

                <>
                    {show === 3 && <>
                        <h3 className={Style.BackIcon} onClick={() => { setShow(2) }}><ArrowBackIosIcon /></h3>
                        <div style={{ maxHeight: "600px", overflowY: "scroll" }}>
                            {template === "1" && <GmailPageOne id={123456} newsletterData={previewData} newsletterId={123456789} click={false} setShow={setShow} />}
                            {template === "2" && <GmailPageTwo id={123456} newsletterData={previewData} newsletterId={123456789} click={false} setShow={setShow} />}
                            {template === "3" && <GmailPageThree id={123456} newsletterData={previewData} newsletterId={123456789} click={false} setShow={setShow} />}
                            {template === "4" && <GmailPageFour id={123456} newsletterData={previewData} newsletterId={123456789} click={false} setShow={setShow} />}
                        </div>
                    </>}
                </>}





        </>
    )
}

export default History