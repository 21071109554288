import React, { useEffect, useState, useRef } from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import Style from "../RightSlide/RightSlide.module.css"
import Box from '@mui/material/Box';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { isAuthenticated } from '../../../auth';
import MenuItem from '@mui/material/MenuItem';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';
import Textarea from '@mui/joy/Textarea';
import ImgCrop from 'antd-img-crop';
import { Input, Select, Upload } from 'antd';
import { TextField } from '@mui/material';

export default function RightSlide({ CheckValue, Categorys, events, itemId, ciityId, cityName, refersh, setRefresh }) {

    const fileInputRef = useRef(null);

    const { token, id, permission } = isAuthenticated();

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [formData, setFormData] = useState({
        event_title: "",
        event_description: "",
        event_booking_url: "",
        event_location_url: "",
        event_venue: "",
        event_address: "",
        event_start_time: "",
        event_end_time: "",
        event_start_date: "",
        event_category: "",
        event_end_date: "",
        event_expiry_date: "",
        event_price: "",
        city_id: ciityId,
        city_name: cityName,
        name: "",
        phone_number: "",
        email: "",
        event_time_description: ""
    })

    // Add Event Code 



    const [edit, setEdit] = useState(false)



    const [fileList, setFileList] = useState([]);
    const [disableField, setDisableField] = useState(false)
    const handleEdit = async (id) => {
        setEdit(true);
        // console.log(id);
        const data = await events.filter(item => item._id === id)
        // console.log(data);
        setFormData({
            ...formData,
            event_title: data[0]?.event_title,
            event_description: data[0]?.event_description,
            event_booking_url: data[0]?.event_booking_url,
            event_location_url: data[0]?.event_location_url,
            event_venue: data[0]?.event_venue,
            event_address: data[0]?.event_address,
            event_price: data[0]?.event_price,
            name: data[0]?.event_contact_person.name,
            phone_number: data[0]?.event_contact_person.phone_number,
            email: data[0]?.event_contact_person.email,
            event_start_time: data[0]?.event_start_time,
            event_end_time: data[0]?.event_end_time,
            event_start_date: data[0]?.event_start_date.split('T')[0],
            event_end_date: data[0]?.event_end_date.split('T')[0],
            event_expiry_date: data[0]?.event_expiry_date.split('T')[0],
            event_category: data[0]?.event_category.split('T')[0],
            city_id: data[0]?.event_location.city.city_id,
            city_name: data[0]?.event_location.city.city_name,
            event_time_description: data[0]?.event_time_description
        })
        if (data[0].event_time_description) {
            setDisableField(true)
        }
        else { setDisableField(false) }

        const url = { url: data[0]?.event_images.square_image }
        setFileList([url])
        // setFileList({url:data[0]?.event_images.square_image})
    };

    const handleInputChange = (event) => {
        const currentDate = new Date().toISOString().split('T')[0];
        // if (event.target.name === 'event_start_date' && event.target.value < currentDate) {
        //     return;
        // }
        if (event.target.name === 'event_end_date' && event.target.value < formData.event_start_date) {
            return;
        }
        if (event.target.name === 'event_expiry_date' && event.target.value < formData.event_start_date) {
            return;
        }
        if (event.target.name === 'event_description') {
            if (event.target.value.length <= 200) {
                setFormData((prevState) => ({
                    ...prevState,
                    [event.target.name]: event.target.value,
                }));
            }
            else {
                return
            }
        }
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };



    const [emptyFieldError, setEmptyFieldError] = useState()
    const url = process.env.REACT_APP_API_URL
    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log(fileList[0].originFileObj);
        if (
            formData.event_description === "" ||
            // formData.event_booking_url === "" ||
            // formData.event_location_url === "" ||
            formData.event_venue === "" ||
            // formData.event_address === "" ||
            // formData.event_start_time === "" ||
            // formData.event_end_time === "" ||
            formData.event_start_date === "" ||
            formData.event_category === "" ||
            formData.event_end_date === "" ||
            formData.event_expiry_date === "" ||
            // formData.event_price === "" ||
            formData.city_id === "" ||
            formData.city_name === "" ||
            // formData.name === "" ||
            // formData.phone_number === "" ||
            // formData.email === "" ||
            fileList.length === 0
            // ||
            // previewURL === ""
        ) {
            setEmptyFieldError("All field are required")
            return
        }
        const startDate = new Date(formData.event_start_date);
        const expiryDate = new Date(formData.event_expiry_date);
        if (startDate > expiryDate) {
            setEmptyFieldError("Expiry date cannot be less than start date")
            return
        }

        if (edit === false) {
            const data = new FormData();
            for (const key in formData) {
                data.append(key, formData[key]);
            }
            data.append('square_image', fileList[0].originFileObj);
            await fetch(`${url}/event/create?staff=${id}`, {
                method: 'POST',
                body: data
            })
                .then(response => {
                    if (response.error) {
                        console.log(response.error);
                    } else {
                        setFormData({
                            ...formData,
                            event_title: '',
                            event_description: '',
                            event_booking_url: '',
                            event_location_url: '',
                            event_venue: '',
                            event_address: '',
                            event_price: '',
                            name: '',
                            phone_number: '',
                            email: '',
                            event_start_time: '',
                            event_end_time: '',
                            event_start_date: '',
                            event_end_date: '',
                            event_expiry_date: '',
                            event_category: '',
                            city_id: ciityId,
                            city_name: cityName,
                            event_time_description: ""
                        })
                        setFileList([])
                        toast.success('Event Added successfully', {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                })
        }
        else {
            // console.log(formData);
            const data = new FormData();
            for (const key in formData) {
                data.append(key, formData[key]);
            }
            if (typeof fileList[0].originFileObj === "object") {
                data.append("image_edited", 1)
                data.append('square_image', fileList[0].originFileObj);
            }
            else {
                data.append("image_edited", 0)
            }
            await fetch(`${url}/event/update?id=${itemId}&staff=${id}`, {
                method: 'PUT',
                body: data
            })
                .then(response => {
                    if (response.error) {
                        console.log(response.error);
                    } else {

                        toast.success('Event Updated Successfully', {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                })
        } setRefresh(!refersh)
    };

    // console.log(refersh);


    // Toggle Drawer 

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        if (CheckValue === 2 && open === true) {
            handleEdit(itemId)
        }

        setState({ ...state, [anchor]: open });
        console.log('Open')
    };

    const closeDrawer = (anchor, open) => (event) => {
        if (formData.event_title === "" ||
            formData.event_description === "" ||
            // formData.event_booking_url === "" ||
            // formData.event_location_url === "" ||
            formData.event_venue === "" ||
            // formData.event_address === "" ||
            // formData.event_start_time === "" ||
            // formData.event_end_time === "" ||
            formData.event_start_date === "" ||
            formData.event_category === "" ||
            formData.event_end_date === "" ||
            formData.event_expiry_date === "" ||
            // formData.event_price === "" ||
            formData.city_id === "" ||
            formData.city_name === "" ||
            // formData.name === "" ||
            // formData.phone_number === "" ||
            // formData.email === "" ||
            fileList.length === 0
            // ||
            // previewURL === ""
        ) {
            return
        }
        const startDate = new Date(formData.event_start_date);
        const expiryDate = new Date(formData.event_expiry_date);
        if (startDate > expiryDate) {
            return
        }
        setState({ ...state, [anchor]: open });
    }
    // List Of Right Slide 
    // console.log('image', previewURL)
    const [imageSizeError, setImageSizeError] = useState()
    const onChange = ({ fileList: newFileList }) => {
        // console.log(newFileList);
        const maxSize = 1 * 1024 * 1024
        if (newFileList.length > 0 && newFileList[0].size > maxSize) {
            setImageSizeError("Image size should be less than 1 MB")
            return
        }
        let x;
        if (fileList.length > 1) {
            x = fileList.filter(data => data.uid !== fileList[0].uid)

        } else { x = newFileList }
        console.log(x);
        setFileList(x)
        setImageSizeError()
    };




    const list = (anchor) => (
        <Box
            className={Style.rightSlide}
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500 }}
            role="presentation"
        >
            <div className='rightSlide_head'><span onClick={toggleDrawer(anchor, false)} ><IconButton><CloseOutlinedIcon sx={{ color: 'white' }} /></IconButton></span><p>{CheckValue === 1 ? "Add Listing" : "Update Listing"}</p></div>
            <div className={Style.rightSlide_form}>
                <form onSubmit={handleSubmit}>
                    <div className={Style.rightSlide_formInput}>

                        <label>
                            Title*:
                            <Input value={formData.event_title} className={Style.rightSlide_formInput_input} name='event_title' placeholder="Enter Title *" variant="outlined" onChange={handleInputChange} />
                        </label>

                        <label>
                        Description: 
                            <Input.TextArea style={{border:"1px solid black",color:"black"}} minRows={4} showCount maxLength={200} size="md" variant="outlined" value={formData.event_description} id="outlined-basic-2" name='event_description' placeholder='Enter Description *' onChange={handleInputChange} />
                        </label>

                        <label>
                            More Details Link:
                       <Input value={formData.event_booking_url} className={Style.rightSlide_formInput_input} name='event_booking_url' placeholder='More Details Link ' variant="outlined" onChange={handleInputChange} />
                        </label>
                        <label>
                            Location URL:
                        <Input value={formData.event_location_url} className={Style.rightSlide_formInput_input} name='event_location_url' placeholder='Enter Location URL ' variant="outlined" onChange={handleInputChange} />
                        </label>
                        <label>
                            Venue*:
                        <Input value={formData.event_venue} className={Style.rightSlide_formInput_input} name='event_venue' placeholder='Enter Venue Name *' variant="outlined" onChange={handleInputChange} />
                        </label>
                        <label>
                            Address:
                        <Input value={formData.event_address} className={Style.rightSlide_formInput_input} name='event_address' placeholder='Enter Venue Address ' variant="outlined" onChange={handleInputChange} />
                        </label>
                        <label>
                            Price:
                        <Input value={formData.event_price} className={Style.rightSlide_formInput_input}  name='event_price' placeholder='Enter Pricing ' variant="outlined" onChange={handleInputChange} />
                        </label>
                        <label>
                        Contact Person&apos;s Name:
                        <Input value={formData.name} className={Style.rightSlide_formInput_input}  name='name' placeholder='Contact Person&apos;s Name ' variant="outlined" onChange={handleInputChange} />
                        </label>
                        <label>
                        Contact Person&apos;s Phone Number:
                        <Input value={formData.phone_number} className={Style.rightSlide_formInput_input}  name='phone_number' placeholder='Contact Person&apos;s Phone Number *' variant="outlined" onChange={handleInputChange} />
                        </label>
                        <label>
                        Contact Person&apos;s Email:
                        <Input value={formData.email} className={Style.rightSlide_formInput_input}  name='email' placeholder='Contact Person&apos;s Email ID ' variant="outlined" onChange={handleInputChange} />
                        </label>
                        
                        <p style={{ display: "flex", justifyContent: "space-between", padding: "0 20px 0 0" }}>
                            <span>Manually Enter Timing Details?</span>
                            <input checked={disableField}  type='checkbox' value={disableField} onChange={() => setDisableField(!disableField)} />
                        </p>
                        <label>
                            Time Details
                        <Input disabled={!disableField} value={formData.event_time_description} className={Style.rightSlide_formInput_input} name='event_time_description' placeholder='Time Details' variant="outlined" onChange={handleInputChange} />
                        </label>
                        Start Date *:<Input value={formData.event_start_date} type='date' className={Style.rightSlide_formInput_input} name='event_start_date' variant="outlined" onChange={handleInputChange} />
                        Start Time:<Input disabled={disableField} value={formData.event_start_time} step="any" type="time" className={Style.rightSlide_formInput_input} name='event_start_time' variant="outlined" onChange={handleInputChange} />
                        End Date *:<Input value={formData.event_end_date} type='date' className={Style.rightSlide_formInput_input} name='event_end_date' variant="outlined" onChange={handleInputChange} />
                        End Time:<Input disabled={disableField} value={formData.event_end_time} step="any" type="time" className={Style.rightSlide_formInput_input} name='event_end_time' variant="outlined" onChange={handleInputChange} />
                        Expiry Date *:<Input value={formData.event_expiry_date} type='date' className={Style.rightSlide_formInput_input} name='event_expiry_date' variant="outlined" onChange={handleInputChange} />
                    </div>
                    <div className={Style.rightSlide_formSelect}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Select Category *"
                          
                            defaultValue="User"
                            name='event_category' onChange={handleInputChange} value={formData.event_category}
                        >
                            {Categorys && Categorys.map((data, index) => (
                                <MenuItem key={index} value={data._id}>
                                    {data.categoryName}
                                </MenuItem>
                            ))}
                        </TextField>
                        
                    </div>
                    <ImgCrop modalClassName="custom-ant-modal-weekendo" aspect={1 / 1} modalOk="Crop" showGrid showReset>
                        <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}

                        >
                            {fileList.length !== 1 && '+ Upload Image'}
                        </Upload>
                    </ImgCrop>
                    {imageSizeError && <p style={{ color: "red", fontSize: "14px" }}>{imageSizeError}</p>}
                    {emptyFieldError && <p style={{ color: "red", fontSize: "14px" }}>{emptyFieldError}</p>}
                    <div className={Style.rightSlide_formSubmit}>
                        <Button onClick={closeDrawer(anchor, false)} variant="contained" type='submit' className='addBtn' style={{ marginTop: '10px' }} >{CheckValue === 1 ? "Add Listing" : "Save Listing"} </Button>
                    </div>
                </form>
            </div>
        </Box>
    );


    // console.log(images)

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>

                    {CheckValue === 1 ? <><Button sx={{ background: '#1976d2', color: 'white' }} className='buttonHover ButtonResponsive ' onClick={toggleDrawer(anchor, true)} >Add Listing</Button>
                    </> : CheckValue === 2 ? <><span className='hoverIcon' onClick={toggleDrawer(anchor, true)}   ><IconButton><BorderColorOutlinedIcon /></IconButton></span>
                    </> : ''}

                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
            {/* <ToastContainer /> */}
        </div>
    );
}