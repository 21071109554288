import Button from '@mui/material/Button';

import Divider from '@mui/material/Divider';

import Menu from '@mui/material/Menu';
import FilterListIcon from '@mui/icons-material/FilterList';
import Select from 'react-select';
import { useRef, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const FilterCategorybutton = ({ filterCity, setFilterCity, setFilteruser, filterUser, Locations }) => {

    const [filterPopOpen, setFilterPopUpOpen] = useState(null)

    const open = Boolean(filterPopOpen);


    const handleToggleFilter = (event) => {
        setFilterPopUpOpen(event.currentTarget);
    };


    const handleCloseFilter = () => {

        setFilterPopUpOpen(null);
    };



    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            width: 280,
            marginTop: theme.spacing(1),
            color:
                theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5)
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity
                    ),
                },
            },
        },
    }));





    const handleCheckboxChangeCity = (event) => {

        const { name, value, checked } = event.target;

        if (checked) {
            setFilterCity((prevCategory) => [...prevCategory, value]);
        } else {
            setFilterCity((prevCategory) =>
                prevCategory.filter((category) => category !== value)
            );
        }
    };
    const [showcountCity, setshowcountCity] = useState(3)

    return (

        <>
            <div>
                <Button
                    sx={{ width: "max-content", height: "max-content" }}
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="outlined"
                    disableElevation
                    onClick={handleToggleFilter}
                    endIcon={<FilterListIcon />}
                    className='drag'
                >
                    Filter
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={filterPopOpen}
                    open={open}
                    transitionDuration={0}
                    onClose={handleCloseFilter}
                >

                    <p style={{ padding: "15px 0px 5px 15px", fontSize: "18px" }}>
                        Select City
                    </p>


                    {Locations && Locations.slice(0, showcountCity).map((data, index) => (
                        <label>
                            <MenuItem >
                                <label style={{ display: 'flex', gap: '5px',cursor:"pointer" }} ><input name='location' type='checkbox' value={data._id} checked={filterCity.includes(data._id)} onChange={handleCheckboxChangeCity} />{data.locationName}</label>
                            </MenuItem>
                        </label>
                    ))}
                    <div>
                        {
                            showcountCity === Locations.length ?
                                <p onClick={() => setshowcountCity(3)} style={{ padding: "7px 0 10px 15px", cursor: "pointer", color: "rgba(123, 163, 223, 1)" }}>View Less</p>
                                : <p onClick={() => setshowcountCity(Locations.length)} style={{ padding: "7px 0 10px 15px", cursor: "pointer", color: "rgba(123, 163, 223, 1)" }}>View All..</p>
                        }
                    </div>
                    <Divider />


                    <Button className='hoverIcon' sx={{ margin: "10px 0 10px 15px", background: "rgba(123, 163, 223, 1) ", color: "white " }} onClick={(e) => { setFilteruser(!filterUser); handleCloseFilter() }}>Apply Filter</Button>




                </StyledMenu>

            </div >

        </>
    )
}

export default FilterCategorybutton