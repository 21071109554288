import React, { useEffect, useRef, useState } from 'react'
import Style from "./TemplateThree.module.css"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Gmail_Page } from '../../../controllers/newsletter';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import LinkIcon from '@mui/icons-material/Link';

const TemplateThree = ({ newsletterData,newCategoryDescription }) => {



  // console.log(newsletterData);
  const replacedHtmlCode = newsletterData && newsletterData.editorNote.replace(/&lt;br\/&gt;/g, '<br>');
  const replacedHtmlCodefooter_content = newsletterData && newsletterData?.footerData.footer_content.replace(/&lt;br\/&gt;/g, '<br>');
  const WhiteStriptext = newsletterData && newsletterData?.footerData.copy_right.replace(/&lt;br\/&gt;/g, '<br>');
  const [expandedSectionId, setExpandedSectionId] = useState(null);


  const [expandedCardId, setExpandedCardId] = useState(null)

  const [showCard, setShowCard] = useState(2)
  // console.log(expandedSectionId);




  let counter = -1
  let remainingads = newsletterData && newsletterData.ad.length


  const Adbanner = () => {
    if (newsletterData.ad.length > counter + 1) {
      counter = counter + 1
      remainingads = remainingads - 1
      return (
        <div>
          <img src={`${newsletterData.ad[counter]?.ad_images.banner_image}`} width="100%" height="100%" style={{ borderRadius: '7px' }} />
        </div>)
    } else {
      return
    }
  }






  const convertTimeTo12HrFormat =(time24)=> {
    let [hours, minutes] = time24.split(':');
    let period = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12;
    return `${hours}:${minutes} ${period}`;
  }









  return (

    <>

      {/* {!click && <h3 style={{cursor:"pointer"}} onClick={()=>{setShow(2)}}>Go Back</h3>} */}
      <div className={Style.main_body}>
        <div className={Style.mailIntro}>
          <div className={Style.mailIntro_body}>
            <img src="\Assets\MailAsset\Logo.svg" alt="logo" width={100} height={50} />
            <img src="\Assets\Email_Template_3\small-stripe.png" height={14} width={120} />
            <div className={Style.mailIntro_bodyContent} style={{ height: '70%' }} >
              <div className={Style.mailIntro_bodyContent_deafault}>
                <div style={{ fontSize: '10px', fontWeight: '400', height: 'auto' }} dangerouslySetInnerHTML={{ __html: replacedHtmlCode }} ></div>
              </div>
            </div>
          </div>
          <img src="\Assets\Email_Template_3\large-stripe.png" height={13} style={{ margin: '12px 21px 12px 0' }} />
        </div>
        {/* Section One  */}
        {newsletterData &&
          newsletterData?.events.map((item, index) => {
            let slice_card = item.category_id === expandedSectionId ? item.category_data.length : showCard
            return <div key={index}>
              <div className={Style.mailAd}>
                {/* <img src="" alt="Ad" /> */}
                {/* <div className='' style={{ backgroundColor: 'white', border: '2px solid black', height: '100px', width: '100%' }} ></div> */}
                {Adbanner()}
                <div style={{ display: "flex", justifyContent: "end" }}>
                <p style={{ fontSize: "7px", borderRadius: "5px", padding: "0px 7px", backgroundColor: "#9c9c9c", color: "white", width: "max-content" }}>AD</p>
                </div>
              </div>
              <div id={item.category_id} className={Style.mailSections} key={index} style={{ backgroundColor: "rgba(253, 231, 205, 0.5)" }} >
                <div className={Style.mailSections_intro} style={{ margin: '20px 20px 0px 20px' }} >
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '5px' }}>
                    <h1>{item.category_name}</h1>
                    <img src="\Assets\Email_Template_3\small-stripe.png" style={{ height: '14px', width: '70px', marginLeft: '5px' }} />
                  </div>
                  <img src="\Assets\Email_Template_3\mask.png" height="70px" />
                </div>



                <p style={{ fontWeight: '300', wordWrap: 'break-word' }} >{newCategoryDescription?.filter(data => data.CategoryName === item.category_name)[0]?.description}</p>



                <div className={Style.mailSections_cards} >
                  {
                    item.category_data.map((card, idx) => {
                      return <>
                        <div key={idx} className={Style.mailSections_cardsDesign}>
                          <img src={card.event_images.square_image} alt="Card Image" />
                          <div className={Style.mailSections_cardsDesign_Content}>
                            <p style={{ fontSize: '18px', fontWeight: '600', lineHeight: "140%" }} >{card.event_title}</p>
                            <p style={{ fontSize: '12px' }} ><span style={{ fontSize: '12px', fontWeight: '600' }} >Venue</span> - {card.event_venue}</p>
                            <p style={{ fontSize: '12px' }}>
                              <span style={{ fontSize: '12px', fontWeight: '600' }}>Date</span> :{' '}
                              {card.event_start_date &&
                                card.event_end_date &&
                                `${new Date(card.event_start_date).toLocaleDateString('en-US', {
                                  month: 'long',
                                  day: 'numeric',
                                  year: 'numeric'
                                })} - ${new Date(card.event_end_date).toLocaleDateString('en-US', {
                                  month: 'long',
                                  day: 'numeric',
                                  year: 'numeric'
                                })}`}
                            </p>

                            {(card.event_time_description || (card.event_start_time && card.event_end_time) )&&

                              <p style={{ fontSize: '12px' }}><span style={{ fontSize: '12px', fontWeight: '600' }} >Time</span>: {card.event_time_description ? <>{card.event_time_description}</> : <>{convertTimeTo12HrFormat(card.event_start_time)} - {convertTimeTo12HrFormat(card.event_end_time)}</>}</p>
                            }
                            {
                              expandedCardId === card._id ?
                                <>
                                  <p style={{ fontSize: '12px' }}>{card.event_description}</p>
                                </>
                                :
                                <p style={{ fontSize: '12px' }}>{card.event_description.slice(0, 100)}{card.event_description.length > 100 ? "......." : ""}</p>
                            }

                            {
                              expandedCardId === card._id ? <>
                                <div className={Style.mailSections_cardsDesign_Content_expandedSection} style={{ display: 'flex', gap: '5px', flexDirection: 'column' }} >
                                  {card.event_price && <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center', gap: '5px' }} ><CurrencyRupeeIcon style={{ backgroundColor: 'rgba(4, 142, 203, 0.12)', color: 'rgba(4, 142, 203, 1)', height: '16px', width: '16px', padding: '3px', borderRadius: '50%' }} /> {card.event_price}</p>}
                                  {card.event_contact_person.name && <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center', gap: '5px' }} ><AccountCircleIcon style={{ backgroundColor: 'rgba(4, 142, 203, 0.12)', color: 'rgba(4, 142, 203, 1)', height: '16px', width: '16px', padding: '3px', borderRadius: '50%' }} /> {card.event_contact_person.name}</p>}
                                  {card.event_contact_person.phone_number && <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center', gap: '5px' }} ><LocalPhoneIcon style={{ backgroundColor: 'rgba(4, 142, 203, 0.12)', color: 'rgba(4, 142, 203, 1)', height: '16px', width: '16px', padding: '3px', borderRadius: '50%' }} /> {card.event_contact_person.phone_number}</p> }
                                  {card.event_contact_person.email && <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center', gap: '5px' }} ><EmailIcon style={{ backgroundColor: 'rgba(4, 142, 203, 0.12)', color: 'rgba(4, 142, 203, 1)', height: '16px', width: '16px', padding: '3px', borderRadius: '50%' }} /> {card.event_contact_person.email}</p>}
                                  {card.event_address && <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center', gap: '5px' }} ><LocationOnIcon style={{ backgroundColor: 'rgba(4, 142, 203, 0.12)', color: 'rgba(4, 142, 203, 1)', height: '16px', width: '16px', padding: '3px', borderRadius: '50%' }} /> {card.event_address}</p>}
                                  {card.event_booking_url && <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center', gap: '3px' }} ><LinkIcon style={{ backgroundColor: 'rgba(4, 142, 203, 0.12)', color: 'rgba(4, 142, 203, 1)', height: '16px', width: '16px', padding: '1px', borderRadius: '50%' }} />More Details</p>}
                                </div>
                                <p onClick={() => setExpandedCardId(0)} style={{ fontWeight: '600', fontSize: '12px', cursor: 'pointer' }} >Read less</p>
                              </> :
                                <>
                                  <p onClick={() => setExpandedCardId(card._id)} style={{ fontWeight: '600', fontSize: '12px', cursor: 'pointer' }} >Read More</p>
                                </>
                            }


                          </div>
                        </div>
                      </>
                    })
                  }
                </div>

                <div className={Style.mailSections_bottom} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} >
                  {/* {item.category_data.length > 2 &&
                    (expandedSectionId !== item.category_id ?
                      <p onClick={() => setExpandedSectionId(item.category_id)} style={{ textAlign: 'center', cursor: "pointer" }}>
                        <p style={{ fontFamily: "sans-serif" }}>Explore More</p>
                        <img src="\Assets\Email_Template_3\read_more_icon.png" style={{ width: '30px' }} />
                      </p>
                      :
                      <p onClick={() => setExpandedSectionId(null)}>
                        <img src="\Assets\Email_Template_3\read_more_icon.png" style={{ width: '20px', transform: "rotate(180deg)", cursor: "pointer" }} />
                      </p>

                    )
                  } */}
                  <img src="\Assets\Email_Template_3\happypeople.png" className={Style.happy_people} />
                </div>
              </div>
            </div>
          })
        }





        {/* Remaining Ads */}


        {newsletterData.ad.length > 0 && newsletterData.ad?.slice(newsletterData.ad.length - remainingads).map((item) => {
          return (
            <div className={Style.mailAd}>
              <img src={item?.ad_images.banner_image} width="100%" height="100%" style={{ borderRadius: '7px' }} />
              <div style={{ display: "flex", justifyContent: "end" }}>
              <p style={{ fontSize: "7px", borderRadius: "5px", padding: "0px 7px", backgroundColor: "#9c9c9c", color: "white", width: "max-content" }}>AD</p>
              </div>
            </div>
          )
        })}












        <div className={Style.mailFooter} style={{ position: 'relative' }} >
          <div className={Style.mailFooter_upper} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} ></div>

          <div className={Style.mailFooter_lower} style={{
            padding: '50px 0 20px 0', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '20px', background: 'rgba(57, 54, 70, 0.98)', color: 'white'
          }} >
            <div className={Style.mailFooter_lowerLinks} style={{ display: 'flex', justifyContent: 'space-around', width: '100%', flexWrap: "wrap" }} >
              <div className={Style.mailFooter_lowerLinks_first} style={{ display: 'flex', alignItems: 'center', fontSize: '10px', gap: '5px', color: 'rgba(35, 212, 230, 1)' }} >
                <a style={{ textDecoration: "none", color: "rgb(35, 212, 230)", display: "flex", alignItems: "center" }} href={`https://wa.me/${newsletterData?.footerData.whats_app_number}`}>
                  <img src="\Assets\MailAsset\whatsappLogo.svg" alt="" height={12} width={12} />&nbsp;
                  {/* {newsletterData?.footerData.whats_app_number} */}
                  +{newsletterData?.footerData.whats_app_number.slice(1, 3)} {newsletterData?.footerData.whats_app_number.slice(3, 8)} {newsletterData?.footerData.whats_app_number.slice(8)}
                </a>
              </div>
              <div className={Style.mailFooter_lowerLinks_mid} style={{ display: 'flex', gap: '10px' }} >
                <a href={newsletterData?.footerData.linkedin_link} className={Style.linkedinLink} style={{ display: 'flex', alignItems: 'center', fontSize: '10px', gap: '5px', color: 'rgba(35, 212, 230, 1)' }}>
                  <img src="\Assets\MailAsset\linkedinLogo.svg" alt="" height={12} width={12} />
                  {/* {newsletterData?.footerData.linkedin_link} */}
                  Linkedin
                </a>
                <a href={newsletterData?.footerData.instagram_link} className={Style.instaLink} style={{ display: 'flex', alignItems: 'center', fontSize: '10px', gap: '5px', color: 'rgba(35, 212, 230, 1)' }} >
                  <img src="\Assets\MailAsset\instaLogo.svg" alt="" height={12} width={12} />
                  {/* {newsletterData?.footerData.instagram_link} */}
                  Instagram
                </a>
              </div>
              <div className={Style.mailFooter_lowerLinks_last} style={{ display: 'flex', alignItems: 'center', gap: '5px', color: 'rgba(35, 212, 230, 1)' }} >
                <img src="\Assets\MailAsset\arrow.svg" alt="" height={12} width={12} />
                <a href={`mailto:${newsletterData?.footerData.email_id}`} style={{ fontSize: '10px', color: 'rgba(35, 212, 230, 1)' }} >getalife@weekendo.in</a>
              </div>
            </div>


            {/* <div style={{ fontSize: '12px !important', maxWidth: '700px',display:"flex",justifyContent:"center !important"}} dangerouslySetInnerHTML={{ __html: replacedHtmlCodefooter_content }}></div>
                         */}
            <div style={{ fontSize: '12px !important', maxWidth: '705px', padding: "5px 10px", display: "flex", justifyContent: "center !important" }}>
              <p style={{ textAlign: "center" }} className={Style.footer_desc} dangerouslySetInnerHTML={{ __html: replacedHtmlCodefooter_content }}></p>
            </div>



            {/* <p style={{ fontSize: '12px !important', textAlign: "center" }} >This email was sent to <a href="" style={{ color: 'rgba(35, 212, 230, 1)' }} >aditchouhan@gmail.com.</a> You can <a href="unsubscribe" style={{ color: 'rgba(35, 212, 230, 1)' }} ></a> or <a href="" style={{ color: 'rgba(35, 212, 230, 1)' }} >learn more</a> about advertising.</p> */}
            <div className={Style.mailFooter_lowerAdWithUS} style={{ fontSize: '12px !important', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', padding: '5px 0px', width: '100%', gap: '5px' }} >
              {/* <img src="\Assets\MailAsset\adwithusLogo.png" alt="" height={12} width={12} />*/}
              <p className={Style.footer_desc} dangerouslySetInnerHTML={{ __html: WhiteStriptext }}></p>
              {/* <p style={{ fontSize: '10px', fontWeight: '600', color: 'black', textAlign: 'center' }} >BUY AD SPACE: <span style={{ fontSize: '10px', fontWeight: '400', color: 'black' }} >To advertise with us, please call us on +91 90111 34922 </span></p> */}
            </div>
          </div>
          <div className={Style.character}>
            <img src="\Assets\Email_Template_3\tribe_female.png" className={Style.footer_girl} alt="" />
            <img src="\Assets\Email_Template_3\footer_mask.png" className={Style.footer_logo} alt="" />
            <img src="\Assets\Email_Template_3\tribe_male.png" className={Style.footer_boy} alt="" />
          </div>
        </div>


      </div >


    </>

  )
}

export default TemplateThree
