import React, { useEffect, useState } from 'react'
import Style from "./staff.module.css"
import AddStaff from '../../components/addStaff';
import { isAuthenticated } from '../../auth';
import { deletEvent, deletStaff, get_staff_by_id, listStaff } from '../../controllers/staff';
import Table from '../../components/Tables/Table';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PopUp from './PopUp/PopUp';
import { Modal } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Time from 'react-time-format'
import { listLocation } from '../../controllers/location';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Result, Spin } from 'antd';

const Staff = () => {

  const { token, id } = isAuthenticated();

  const [staffs, setStaffs] = useState([])
  const [refersh, setRefresh] = useState(true)
  const [Locations, setLocations] = useState([])
  const init = async () => {
    listStaff(id, token).then(data => {
      setStaffs(data)
    }).catch(error => console.error(error));
    try {
      const locationData = await listLocation(id, token);
      setLocations(locationData);
    } catch (error) {
      console.error(error);
    }
  };

  const [permission, setPermission] = useState()
  const fetch_user_data = () => {
    get_staff_by_id(id).then(data => {
      if (data.error) { console.log(data.error); }
      else { setPermission(data.permission) }
    })
  }

  useEffect(() => {
    fetch_user_data()
  }, [])


  useEffect(() => {
    init();
  }, [refersh]);
  const [deleteId, setDeleteid] = useState()

  const handleDelete = () => {
    deletStaff(id, deleteId, token).then(data => {
      if (data.message) {
        toast.success(data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } if (data.error) {
        toast.error(data.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      init();
      setOpen(false)
    })
  }





  // Table Content 



  const [reverse, setreverse] = useState()

  const reverseArray = () => {
    const x = staffs?.length > 0 ? staffs?.reverse() : []
    setreverse(x)
  }

  useEffect(() => {
    reverseArray()
  }, [staffs, refersh])

  const [open, setOpen] = useState(false);

  const handleClickOpen = (id) => {
    setDeleteid(id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const TableHeading = ['Name', 'Email', 'Allowed Cities', 'Phone Number', 'Created On', 'Actions']
  const Keys = ['name', 'email', 'permission', 'phoneNumber', 'createdAt', '_id']
  const TableData = reverse?.filter(item => item._id !== id).map((item) => Keys.map((Key) => {

    if (Key === '_id') {
      return <>
        {permission && permission.staff.edit === 1 && <PopUp refersh={refersh} setRefresh={setRefresh} CheckValue={2} itemid={item._id} />}
        {permission && permission.staff.delete === 1 && <span className='hoverIcon'><DeleteOutlineOutlinedIcon onClick={() => handleClickOpen(item?._id)} /></span>}
      </>
    }
    if (Key === 'name') {
      return `${item.name.firstName} ${item.name.lastName}`
    }
    if (Key === 'createdAt') {
      return <Time value={item.createdAt} format="DD-MM-YYYY" />
    }
    if (Key === 'permission') {
      return item.permission.allowedCities.map(data =>
      (<>
        <button
          disabled
          style={{
            border: "none",
            padding: '5px 20px',
            margin: '0 5px',
            borderRadius: '20px',
            backgroundColor: 'lightgreen',
            color: 'black'
          }}
        >
          {Locations.filter(item => item._id === data).map(data => data.locationName)}
        </button>
      </>))
    }
    else {
      return item[Key] || '';
    }
  }))


  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true);

    const timeout = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [permission])



  return (
    <>
      {/* <AddStaff /> */}

      {/* <PopUp /> */}


      {loading ? <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Spin size='large' />

      </div> :
        <>
          {permission && permission.staff.read === 1 ? <>

            <div className={Style.staff}>
              <div className={Style.staffHead}>
                <p className='headDetail' >
                  <h3>All Staffs</h3>
                  <span className='totalNumbers' >Total Staff : {staffs.length}</span>
                </p>
                {permission && permission.staff.create === 1 && <PopUp refersh={refersh} setRefresh={setRefresh} CheckValue={1} />}


              </div>
              {staffs?.length !== 0 ?
                <>
                  <div className={Style.staffTable}>
                    <Table TableData={TableData } TableHeading={TableHeading} />
                  </div>
                </>
                :
                <>
                  No Staff Available
                </>
              }

            </div>



          </> : <>
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
            />
          </>}

        </>}



      <ToastContainer />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to delete this staff
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant='contained'
            onClick={() => handleDelete(deleteId)}
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

    </>
  )
}

export default Staff
