import React, { useEffect, useState } from 'react'
// import Navbar from '../../components/SideBar/SideBar'
import { listLocation } from '../../controllers/location'
import { isAuthenticated } from '../../auth'
import { deletEvent, listEvent } from '../../controllers/newsletter'
import Table from '../../components/Tables/Table'
import Button from '@mui/material/Button';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useHistory } from 'react-router-dom';
import Style from "./AdModule.module.css"
import RightSlide from "./RightSlide/RightSlide"
import { deletAdd, listAd } from '../../controllers/ad'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';
import Time from 'react-time-format'


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { get_staff_by_id } from '../../controllers/staff'
import { Result, Spin } from 'antd';
import Chip from '@mui/material/Chip';
import moment from 'moment';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


const AdModule = () => {

    const history = useHistory();
    const [show, setShow] = useState(1)

    const [cityName, setcityname] = useState()
    const [ciityId, setCityId] = useState()

    const { token, id } = isAuthenticated();

    const [locationData, setLocationData] = useState([])
    const [check, setCheck] = useState(false);
    const [refersh, setRefresh] = useState(true)
    const [ads, setAds] = useState([])
    // for listing all the locations
    const init = async () => {
        listLocation(id, token).then(data => {
            // console.log(data)
            setLocationData(data)
        }).catch(error => console.error(error));
        await listAd(id, ciityId, token).then(data => {
            setAds(data)
        }).catch(error => console.error(error));
    };
    const [role, setRole] = useState()
    const [permission, setPermission] = useState()
    const fetch_user_data = () => {
        get_staff_by_id(id).then(data => {
            if (data.error) { console.log(data.error); }
            else { setPermission(data.permission); setRole(data.tag) }
        })
    }

    useEffect(() => {
        fetch_user_data()
    }, [])




    const handleAddEvent = async (city_id, city_name) => {
        await listAd(id, city_id, token).then(data => {
            setAds(data)
        }).catch(error => console.error(error));

        // console.log(city_name);

        console.log(ads);
        setcityname(city_name)
        setCityId(city_id)
        setShow(2)
    }

    const handleDelete = (event_id) => {
        // console.log(id);
        setCheck(!check)
        deletAdd(id, event_id, token).then(data => {
            if (data.message) {
                handleClose()
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } if (data.error) {
                toast.error(data.error, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            handleAddEvent(ciityId, cityName)
        })
    }



    // console.log(locationData);

    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteid] = useState()

    const handleClickOpen = (id) => {
        setDeleteid(id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // Table Content 

    // Create Ads City Table 
    const TableHeading = ['City', 'No of Ads', 'Actions']
    const keys = ['locationName', 'locationAds', '_id']

    const filteredData = permission && locationData?.length > 0 && locationData?.filter(data => {
        if (role && role.toString() === process.env.REACT_APP_API_ADMIN_ID) {
            console.log("data");
            return true; // Send all data for admin role
        } else {
            return permission.allowedCities.includes(data._id); // Filter data for staff role
        }
    });

    const TableData = permission && filteredData && filteredData.map(item => keys.map(key => {
        if (key === "_id") {
            return <>
                {permission && permission.ad.read === 1 && <Button className='addBtn' variant="contained" onClick={() => handleAddEvent(item._id, item.locationName)} >Create Ad</Button>}

            </>
        }
        if (key === "locationAds") {
            return item?.locationAds > 0 ? item?.locationAds : 0
        }
        else {
            return item[key] || "";
        }
    })
    );

    console.log(ads);
    // Select City for Ad Creation > Create Ads  Table

    const TableHeadingForCreateAd = ['Placement', 'Campaign Name', 'Client Name', 'Date', 'Expire', 'Status', 'Actions']

    const Keys = ['ad_placement', 'ad_campaign_name', 'ad_client_name', 'ad_start_date', 'ad_expiry_date', 'Status', '_id']

    const TableDataForCreateAd = ads.length > 0 && ads.map(item => Keys.map(Key => {
        if (Key === "_id") {
            return <>
                {permission && permission.ad.edit === 1 && <RightSlide refersh={refersh} setRefresh={setRefresh} ads={ads} itemId={item?._id} CheckValue={2} />}
                {permission && permission.ad.delete === 1 && <span className='hoverIcon'><IconButton><DeleteOutlineOutlinedIcon onClick={() => handleClickOpen(item._id)} /></IconButton></span>}
            </>
        }
        if (Key === 'ad_start_date') {
            return <Time value={item.ad_start_date} format="DD-MM-YYYY" />
        }
        if (Key === 'Status') {
            const expiryDate = moment(item.ad_expiry_date);
            const currentDate = moment();
            const startDate = moment(item.ad_start_date)
            const daysRemaining = expiryDate.diff(currentDate, 'days');
            if (item.ad_images.banner_image === "") {
                return
            }
            if (startDate < currentDate) {
                if (daysRemaining <= 5 && daysRemaining >= 0) {
                    return <Chip label={`Expires in ${daysRemaining} day`} sx={{ backgroundColor: "#fc9803" }} />
                }
                else if (daysRemaining < 0) {
                    return <Chip label="Expired" sx={{ backgroundColor: "#fa5757", color: "white" }} />
                }
                else if (daysRemaining > 5) {
                    return <Chip label="Running" sx={{ backgroundColor: "#3bb317" }} />
                }
            }

            else {
                return <Chip label="Not Started" sx={{ backgroundColor: "White", border: "1px solid black" }} />
            }
        }
        if (Key === 'ad_expiry_date') {
            return <Time value={item.ad_expiry_date} format="DD-MM-YYYY" />
        }
        else {
            return item[Key] || "";
        }
    })
    );

    useEffect(() => {
        init();
    }, [check, refersh]);



    // console.log(ads.length);

    const [loading, setLoading] = useState(true)
    useEffect(()=>{
        setLoading(true);
      
        const timeout = setTimeout(() => {
          setLoading(false);
        }, 500);
      
        return () => clearTimeout(timeout);
    },[show])


    return (
        <>

            {/* Select City for Ad Creation  */}
            {loading ?
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin size='large' />

                </div> :

                <>
                 {show === 1 && <>

<div className={Style.adModule}>
    <div className={Style.adModule_head}>
        <h3>Ad Module</h3>
    </div>

    <div className={Style.adModule_table}>
        <Table TableData={TableData} TableHeading={TableHeading} />
    </div>
</div>
</>}
                 </>}
           


            {/* Select City for Ad Creation > Create Ads  */}
            {loading ?
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin size='large' />

                </div> :

                <> 
                {show === 2 && <>
                {permission && permission.ad.read === 1 ?
                    <div className={Style.adCreation}>
                        <div className={Style.adCreation_head}>
                            <p className='headDetail' >


                                <h3><IconButton onClick={() => setShow(1)}><ArrowBackIosIcon fontSize='small' /></IconButton><span onClick={() => setShow(1)} style={{ color: '#1976d2', cursor: 'pointer' }} >{cityName.charAt(0).toUpperCase() + cityName.slice(1)}</span></h3>
                                <span className='totalNumbers' >Total Ads Running : {ads.length > 0 && ads.filter(data => data.ad_images.banner_image !== "").length}/20</span>

                            </p>
                            {permission && permission.ad.create === 1 && <>
                                {(ads.length === undefined || ads.length < 20)&&
                                    <div className="" style={{ display: 'flex', gap: '10px' }} >
                                        <RightSlide adsLength={ads.length} refersh={refersh} setRefresh={setRefresh} setAds={setAds} CheckValue={1} cityName={cityName} ciityId={ciityId} />
                                    </div>
                                }


                            </>

                            }

                        </div>
                        {ads.length > 0 ?
                            <>
                                <div className={Style.adCreation_table}>
                                    <Table TableData={TableDataForCreateAd} TableHeading={TableHeadingForCreateAd} Admodule={1} ></Table>
                                </div>
                            </>
                            :
                            <>
                                No Running Ad Campaign
                            </>}
                    </div> :
                    <Result
                        status="403"
                        title="403"
                        subTitle="Sorry, you are not authorized to access this page."
                    />
                }

            </>}
                
                </>}

            
            <ToastContainer />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    <strong>Are you sure you want to delete this Ad? </strong>(It will not be shown in the newsletters henceforth.)
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={() => handleDelete(deleteId)} >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AdModule